import React, { useEffect, useState } from "react";
import styles from "./styles";
import logo from "../../assets/icons//logo-light.png";
import { getCompanies, getContact, getCopyright, getLegals, getSocialNetworks } from "../../services/squidex";
import {
  CompaniesResponse,
  ContactResponse,
  CopyrightResponse,
  LanguageCode,
  Legal,
  SocialNetworksResponse
} from "../../utils/types";
import { LanguageStore, useLanguageStore } from "../../store/languageStore";

const Footer: React.FC = () => {
  const language: LanguageCode = useLanguageStore((state: LanguageStore) => state.language);
  const [legals, setLegals] = useState<Legal[]>([]);
  const [companies, setCompanies] = useState<CompaniesResponse>();
  const [copyright, setCopyright] = useState<CopyrightResponse>();
  const [socialNetworks, setSocialNetworks] = useState<SocialNetworksResponse>();
  const [contact, setContact] = useState<ContactResponse>();

  useEffect(() => {
    async function fetchData() {
      const legalsData = await getLegals();
      const companiesData = await getCompanies();
      const copyrightData = await getCopyright();
      const socialNetworksData = await getSocialNetworks();
      const contactData = await getContact();

      setLegals(legalsData);
      setCompanies(companiesData);
      setCopyright(copyrightData);
      setSocialNetworks(socialNetworksData);
      setContact(contactData);
    }

    fetchData().catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.columns}>
        <div className={styles.part}>
          <img src={logo} alt="Ravent logo" className={styles.logo} />
        </div>

        <div className={styles.companies}>
          {companies &&
            companies[language].map((item, index) => (
              <div className={styles.part} key={item.id + index}>
                <h3 className={styles.dirTitle}>{item.region}</h3>
                <a href={item.url} target="_blank" rel="noreferrer">
                  <div style={{ cursor: "pointer" }} className={styles.text}>
                    <strong>{item.name}</strong>
                  </div>
                </a>
                <div className={styles.text}>{item.addressl1},</div>
                <div className={styles.text}>{item.addressl2}</div>
                <div className={styles.text}>{item.phone}</div>
              </div>
            ))}
        </div>

        <div className={styles.part}>
          <div className={styles.text}>
            <h3 className={styles.dirTitle}>Contact us</h3>
            <strong>{contact?.contact}</strong>
          </div>
          <div className={styles.socials}>
            {socialNetworks &&
              socialNetworks.iv.map((item, index) => (
                <a href={item.link} target="_blank" rel="noopener noreferrer" key={item.id + index}>
                  <img
                    src={`https://cms.ravent.com/api/assets/ravent-landing-page/${item.icon[0]}`}
                    alt={item.name}
                    className={styles.icon}
                  />
                </a>
              ))}
          </div>
        </div>
      </div>
      <div className={styles.footerText}>
        <hr className={styles.divisor} />

        <div className={styles.legals}>
          <p className={styles.copy}>{copyright?.[language] || ""}</p>
          <div className={styles.terms}>
            {legals &&
              legals.map((item, index) => (
                <a
                  className={styles.link}
                  href={item.name[language]}
                  key={item.title + index.toString()}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.title[language]}
                </a>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
