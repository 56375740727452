import React from "react";
import { GridContainer } from "../GridContainer/GridContainer";
import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import ReactMarkdown from "react-markdown";
import { Button } from "../Button";

interface Props {
  heading: string;
  title: string;
  name: string;
  image: string;
  button: string;
  buttonLink: string;
  text: string;
  alt: string;
  isVideo: boolean;
}

const Mobile: React.FC<Props> = ({ name, title, image, text, buttonLink, button, heading, isVideo, alt }) => {
  return (
    <div className={styles.content}>
      <GridContainer gap="1em" columns="1fr">
        <div>
          <div className={styles.name}>{name}</div>
          <ReactMarkdown className={styles.title}>{title}</ReactMarkdown>
          <ReactMarkdown className={styles.textSmall}>{text}</ReactMarkdown>
          <a href={buttonLink} target="_blank" rel="noopener noreferrer">
            <Button buttonClassname={styles.download} label={button} />
          </a>
        </div>
        {isVideo ? (
          <video loop autoPlay className={styles.videoContainer}>
            <source src={image} type="video/mp4" className={styles.imageWrapVideo} />
          </video>
        ) : (
          <div className={styles.imageContainer}>
            <img alt={alt || ""} src={image} className={styles.imageWrap} />
          </div>
        )}
      </GridContainer>
      <FlexContainer>
        <div className={styles.heading}>{heading}</div>
      </FlexContainer>
    </div>
  );
};

export default Mobile;
