export default class APIError extends Error {
  statusCode = "";
  messages: [];
  length = 0;
  constructor(error: string, messages: [], statusCode: string) {
    super(error);
    this.statusCode = statusCode;
    this.messages = messages;
  }
}
