import * as React from "react";
import styles from "./styles.module.css";
export interface FlexContainerProps {
  flexDirection?: "inherit" | "initial" | "revert" | "unset" | "column" | "column-reverse" | "row" | "row-reverse";
  flexWrap?: "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "nowrap" | "wrap" | "wrap-reverse";
  alignItems?: string;
  justifyContent?: string;
  padding?: string | number;
  width?: string;
  margin?: string;
  height?: string;
}

export const FlexContainer: React.FC<FlexContainerProps> = ({
  children,
  flexDirection,
  alignItems,
  justifyContent,
  flexWrap,
  padding,
  height,
  width,
  margin
}) => (
  <div
    className={styles.flexContainer}
    style={{ display: "flex", flexDirection, alignItems, justifyContent, flexWrap, padding, width, height, margin }}
  >
    {children}
  </div>
);

FlexContainer.defaultProps = {
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "nowrap"
};
