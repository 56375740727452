import { stylesheet } from "typestyle";

const style = stylesheet({
  container: {
    backgroundColor: "#ffffffee",
    position: "fixed",
    bottom: "0",
    left: "0",
    width: "100%",
    zIndex: 1,
    padding: "2rem",
    lineHeight: "1.5",
    boxShadow: "0px -1px 1px #ddd",
    $nest: {
      a: {
        color: "#1D264E",
        fontWeight: "bold",
        textDecoration: "underline"
      },
      "@media screen and (max-width: 768px)": {
        overflow: "hidden",
        padding: "1rem",
        textAlign: "center"
      }
    }
  },
  text: {
    fontSize: "16px"
  },
  cookiesContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    marginTop: "1rem",
    $nest: {
      "@media screen and (max-width: 768px)": {
        justifyContent: "center",
        marginTop: "1.5rem"
      }
    }
  },
  cookie: {
    display: "flex",
    gap: "0.25rem"
  },
  buttonsContainer: {
    display: "flex",
    marginLeft: "auto",
    width: "fit-content",
    gap: "1em",
    $nest: {
      "@media screen and (max-width: 768px)": {
        marginLeft: 0,
        justifyContent: "center",
        width: "100%",
        marginTop: "2rem"
      }
    }
  },
  buttonAccept: {
    padding: "0.75em 1em",
    backgroundColor: "#1D264E",
    borderRadius: "5px",
    color: "white",
    fontSize: "16px",
    cursor: "pointer",
    width: "10em",
    $nest: {
      "@media screen and (max-width: 768px)": {
        width: "8rem"
      }
    }
  },
  buttonDecline: {
    padding: "0.75em 1em",
    backgroundColor: "#ffffff",
    border: "2px solid #1D264E",
    borderRadius: "5px",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    width: "10em",
    color: "#1D264E",
    $nest: {
      "@media screen and (max-width: 768px)": {
        width: "8rem"
      }
    }
  }
});

export default style;
