import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router";
import { getFunctionalityContent } from "../../services/squidex";
import { Content, LanguageCode } from "../../utils/types";
import styles from "./styles";
import TextImage from "../../components/TextImage";
import UseCaseTextImage from "../../components/UseCaseTextImage";
import Footer from "../../components/Footer";
import { Button } from "../../components/Button";
import TextImageNotBackground from "../../components/TextImageNoBackground";
import { LanguageStore, useLanguageStore } from "../../store/languageStore";
import { HomeCarrouselContentSkeleton } from "../ContentLoader";

const Functionality: React.FC = () => {
  const refFrontPage = useRef<HTMLDivElement>(null);
  const [content, setContent] = useState<Content>();
  const refContact = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<string>("");
  const [isReady, setIsReady] = useState(false);
  const language: LanguageCode = useLanguageStore((state: LanguageStore) => state.language);

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getFunctionalityContent();
        setContent(res);
        setSelected(res.footerCarrousel[language][0].title);
        document.title = `RAVENT • ${res.title[language]}`;
        document.getElementById("preview1")?.setAttribute("content", res.text[language]);
        document.getElementById("previewOg")?.setAttribute("content", res.text[language]);
        document.getElementById("previewTwitter")?.setAttribute("content", res.text[language]);
      } catch (err) {
        console.log(err);
      } finally {
        setIsReady(true);
      }
    };

    fetchData();
  }, [language]);

  const colors = ["#E1F3FF", "#FFF6DE", "#F5F2FF", "#E8FFFE", "#FFEEE6"];

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current != null) {
      history.push("/");
      ref.current.scrollIntoView();
    }
  };

  const buttonTexts = content?.footerCarrousel[language]?.map((item) => item.title);

  return (
    <div ref={refFrontPage}>
      <Header
        openMobile={() => history.push("/menu")}
        onClick={() => executeScroll(refContact)}
        onClickLogo={() => history.push("/")}
      />
      {isReady ? (
        <div className={styles.container}>
          {content ? (
            <UseCaseTextImage
              alt={content.image[language].description}
              isVideo={content.image[language].isVideo}
              heading={content.heading[language] || ""}
              title={content.title[language] || ""}
              name={content.name[language] || ""}
              button={content.headerButton[language].label || ""}
              buttonLink={content.headerButton[language].link || ""}
              text={content.text[language] || ""}
              image={`https://cms.ravent.com/api/assets/ravent-landing-page/${content.image[language].image}` || ""}
            />
          ) : null}

          {content?.bodyContents[language]
            ? content.bodyContents[language]?.map((item, index) => (
                <TextImage
                  key={item.title}
                  alt={item.asset.description}
                  color={colors[index % colors.length]}
                  imageStyle={styles.imageWrap}
                  text={item.text || ""}
                  title={item.title}
                  isVideo={item.asset.isVideo}
                  image={`https://cms.ravent.com/api/assets/ravent-landing-page/${item.asset.image}` || ""}
                />
              ))
            : null}

          <div className={styles.buttonContainer}>
            {content?.footerCarrousel[language]
              ? buttonTexts?.map((item, index) => (
                  <div key={index + "buttons"}>
                    {selected === item ? (
                      <Button
                        label={item}
                        onClick={() => {
                          setSelected(item);
                        }}
                      />
                    ) : (
                      <div
                        onClick={() => {
                          setSelected(item);
                        }}
                        className={styles.buttonText}
                      >
                        {item}
                      </div>
                    )}
                  </div>
                ))
              : null}
          </div>

          {content?.footerCarrousel[language]
            ? content.footerCarrousel[language]?.map((item, index) => (
                <div key={index + "footerCarrousel"} style={{ display: selected === item.title ? "block" : "none" }}>
                  <TextImageNotBackground
                    key={item.title}
                    alt={item.asset.description}
                    imageStyle={styles.imageWrap}
                    text={item.text || ""}
                    title={item.title}
                    isVideo={item.asset.isVideo}
                    image={`https://cms.ravent.com/api/assets/ravent-landing-page/${item.asset.image}` || ""}
                  />
                </div>
              ))
            : null}
        </div>
      ) : (
        <HomeCarrouselContentSkeleton />
      )}
      {content ? (
        <div className={styles.footer}>
          <div className={styles.footerContent}>
            <div className={styles.subtitle}>{content.footerTitle[language]}</div>
            <div className={styles.footerText}>{content.footerText[language]}</div>
          </div>
          {content.footerButton[language].label ? (
            <Button
              onClick={() => history.push(content.footerButton[language].link || "")}
              width="50px"
              buttonClassname={styles.download}
              label={content.footerButton[language].label}
            />
          ) : null}
        </div>
      ) : null}

      <div ref={refContact}>
        <Footer />
      </div>
    </div>
  );
};

export default Functionality;
