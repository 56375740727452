import React from "react";

import loginStyles from "./styles";
import mobile from "../../assets/images/mobile.png";
import apple from "../../assets/images/downloadStore.png";
import android from "../../assets/images/downloadPlay.png";

import { GridContainer } from "../../components/GridContainer/GridContainer";
import { FlexContainer } from "../../components/FlexContainer/FlexContainer";
import { QRCode } from "antd";

interface Props {}

const Download: React.FC<Props> = () => {
  const isMobile = window.innerWidth <= 800;

  return (
    <div className={loginStyles.container}>
      <div className={loginStyles.elementToTransform}>
        {!isMobile ? (
          <GridContainer columns={"1fr 1fr"}>
            <img src={mobile} alt="" className={loginStyles.image} />
            <div className={loginStyles.inputsContainer}>
              <div className={loginStyles.subtitle}>Get the App</div>
              <div className={loginStyles.text}>Scan the QR code to install the app </div>

              <QRCode value={"https://ravent.com/download-app"} />
              <div className={loginStyles.text}>
                ---------------------------- <span className={loginStyles.blue}>OR</span> ----------------------------
              </div>
              <FlexContainer justifyContent="space-between">
                <a target="blank" href="https://apps.apple.com/app/ravent-concierge/id1575005586" download>
                  <img src={apple} alt="" className={loginStyles.button} />
                </a>
                <a target="blank" href="https://play.google.com/store/apps/details?id=com.ravent.concierge" download>
                  <img onClick={() => {}} src={android} alt="" className={loginStyles.button} />
                </a>
              </FlexContainer>
            </div>
          </GridContainer>
        ) : (
          <div className={loginStyles.inputsContainer}>
            <div className={loginStyles.subtitle}>Get the App</div>
            <div className={loginStyles.text}>Scan the QR code to install the app </div>

            <QRCode value={"https://ravent.com/download-app"} />
            <div className={loginStyles.text}>
              ----------------- <span className={loginStyles.blue}>OR</span>  -----------------
            </div>
            <FlexContainer justifyContent="space-between">
              <a target="blank" href="https://apps.apple.com/app/ravent-concierge/id1575005586" download>
                <img src={apple} alt="" className={loginStyles.button} />
              </a>
              <a target="blank" href="https://play.google.com/store/apps/details?id=com.ravent.concierge" download>
                <img onClick={() => {}} src={android} alt="" className={loginStyles.button} />
              </a>
            </FlexContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default Download;
