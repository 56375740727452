import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router";
import { getAboutUsContent } from "../../services/squidex";
import { LanguageCode, TitleContent } from "../../utils/types";
import styles from "./styles";
import Footer from "../../components/Footer";
import { Button } from "../../components/Button";
import { LanguageStore, useLanguageStore } from "../../store/languageStore";
import { AboutUsContenSkeleton } from "../ContentLoader";

const AboutUs: React.FC = () => {
  const refFrontPage = useRef<HTMLDivElement>(null);
  const [content, setContent] = useState<TitleContent>();
  const refContact = useRef<HTMLDivElement>(null);
  const [isReady, setIsReady] = useState(false);

  const language: LanguageCode = useLanguageStore((state: LanguageStore) => state.language);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAboutUsContent();
        setContent(res);
        document.title = `RAVENT • ${res.title[language]}`;
        document.getElementById("preview1")?.setAttribute("content", res.text[language]);
        document.getElementById("previewOg")?.setAttribute("content", res.text[language]);
        document.getElementById("previewTwitter")?.setAttribute("content", res.text[language]);
      } catch (err) {
        console.log(err);
      } finally {
        setIsReady(true);
      }
    };

    fetchData();
  }, [language]);

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current != null) {
      history.push("/");
      ref.current.scrollIntoView();
    }
  };

  return (
    <div ref={refFrontPage}>
      <Header
        openMobile={() => history.push("/menu")}
        onClick={() => executeScroll(refContact)}
        onClickLogo={() => history.push("/")}
      />

      {isReady ? (
        <>
          {content ? (
            <div className={styles.footerNoBackground}>
              <div className={styles.footerContent}>
                <div className={styles.subtitle}>{content.title[language]}</div>
                <div className={styles.text}>{content.text[language]}</div>
                <div className={styles.footerText}>{content.subtitle[language]}</div>
              </div>
            </div>
          ) : null}

          {content?.footerText[language] ? (
            <div className={styles.footer}>
              <div className={styles.footerContent}>
                <div className={styles.subtitle}>{content.footerTitle[language]}</div>

                <div className={styles.footerText}>{content.footerText[language]}</div>
              </div>
              {content.footerButton[language].label ? (
                <Button
                  onClick={() => history.push(content.footerButton[language].link || "")}
                  width="50px"
                  buttonClassname={styles.download}
                  label={content.footerButton[language].label}
                />
              ) : null}
            </div>
          ) : null}
        </>
      ) : (
        <AboutUsContenSkeleton />
      )}

      <div ref={refContact}>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
