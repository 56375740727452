import { stylesheet } from "typestyle";

export interface ButtonTheme {
  backgroundColor: string;
  backgroundColorHover: string;
  textColor: string;
  textColorHover: string;
  borderColor: string;
  borderColorHover: string;
  disabledBackgroundColor: string;
  disabledBorderColor: string;
  disabledTextColor: string;
  textDecoration?: string;
}

export const blueColors: ButtonTheme = {
  backgroundColor: "rgba(36, 109, 241, 1)",
  backgroundColorHover: "rgba(36, 109, 241, 1)",
  textColor: "#fff",
  textColorHover: "#fff",
  borderColor: "rgba(36, 109, 241, 1)",
  borderColorHover: "rgba(36, 109, 241, 1)",
  disabledBackgroundColor: "#dce1e6",
  disabledBorderColor: "#dce1e6",
  disabledTextColor: "#fff"
};

export const whiteColors: ButtonTheme = {
  backgroundColor: "#fff",
  backgroundColorHover: "#dce1e6",
  textColor: "#333",
  textColorHover: "#333",
  borderColor: "#dce1e6",
  borderColorHover: "#dce1e6",
  disabledBackgroundColor: "#dce1e6",
  disabledBorderColor: "#dce1e6",
  disabledTextColor: "#fff"
};

export const orangeColors: ButtonTheme = {
  backgroundColor: "#fff",
  backgroundColorHover: "#FF7832",
  textColor: "#FF7832",
  textColorHover: "#fff",
  borderColor: "#FF7832",
  borderColorHover: "#FF7832",
  disabledBackgroundColor: "#FF7832",
  disabledBorderColor: "#FF7832",
  disabledTextColor: "#fff"
};

export const linkColors: ButtonTheme = {
  backgroundColor: "#fff",
  backgroundColorHover: "#fff",
  textColor: "#0ca4ff",
  textColorHover: "#0ca4ff",
  borderColor: "#fff",
  borderColorHover: "#fff",
  disabledBackgroundColor: "#fff",
  disabledBorderColor: "#fff",
  disabledTextColor: "#dce1e6",
  textDecoration: "underline"
};

export function applyButtonTheme(theme: ButtonTheme) {
  return {
    color: theme.textColor,
    background: theme.backgroundColor,
    borderColor: theme.borderColor,
    borderWidth: 1,
    borderStyle: "solid",
    online: "none",
    fontSize: "16px",
    fontWeight: 500,
    minWidth: "10em",
    padding: "1.3em 1.8em",
    display: "flex",
    height: "50px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "48px",
    $nest: {
      "&:hover": {
        background: theme.backgroundColorHover,
        borderColor: theme.borderColorHover,
        color: theme.textColorHover,
        textDecoration: theme.textDecoration
      },
      "&:disabled": {
        background: theme.disabledBackgroundColor,
        borderColor: theme.disabledBorderColor,
        color: theme.disabledTextColor
      },
      "@media screen and (max-width: 800px)": {
        height: "40px"
      }
    }
  };
}

const styles = stylesheet({
  iconContainer: {
    width: "1em",
    height: "1em",
    marginRight: ".3em"
  },
  iconImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  },

  smallSize: {
    height: "2em"
  },
  mediumSize: {
    height: "2.5em"
  },
  disable: {
    color: "#333333 !important"
  },
  buttonBlue: applyButtonTheme(blueColors),
  buttonWhite: applyButtonTheme(whiteColors),
  buttonOrange: applyButtonTheme(orangeColors),
  buttonLink: applyButtonTheme(linkColors)
});

export default styles;
