import * as React from "react";

import { classes } from "typestyle";
import styles from "./styles";

export interface ButtonProps {
  label?: string;
  size?: "small" | "medium";
  buttonClassname?: string;
  containerClassname?: string;
  theme?: "blue" | "white" | "link" | "orange";
  icon?: string;
  width?: string;
  minWidth?: string;
  height?: string;
  padding?: string;
  display?: "flex" | "block" | "none";
  justifyContent?: "center" | "flex-start" | "flex-end" | "space-between" | "space-evenly" | "space-around ";
  alignItems?: "center" | "flex-start" | "flex-end" | "space-between";
  disable?: boolean;
  type?: "button" | "submit";
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    label,
    size,
    buttonClassname,
    containerClassname,
    theme,
    icon,
    children,
    width,
    minWidth,
    height,
    padding,
    display,
    justifyContent,
    alignItems,
    disable,
    type,
    onClick,
    onFocus,
    onBlur
  } = props;

  return (
    <div className={containerClassname}>
      <button
        type={type || "button"}
        style={{
          width,
          minWidth,
          height,
          padding,
          display,
          justifyContent,
          alignItems
        }}
        className={classes(buttonClassname, {
          [styles.buttonBlue]: theme === "blue",
          [styles.buttonWhite]: theme === "white",
          [styles.buttonOrange]: theme === "orange",
          [styles.buttonLink]: theme === "link",
          [styles.smallSize]: size === "small",
          [styles.mediumSize]: size === "medium",
          [styles.disable]: disable
        })}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disable}
      >
        {icon ? (
          <div className={styles.iconContainer}>
            <img alt={label} src={icon} className={styles.iconImage} />
          </div>
        ) : null}
        <span>{label}</span>
        {children}
      </button>
    </div>
  );
};

Button.defaultProps = {
  label: "Text",
  theme: "blue",
  size: "medium"
};
