import React from "react";
import styles from "./styles";
import chevronDown from "../../assets/icons/chevron-down.svg";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { Menu } from "../../utils/types";
import { useHistory } from "react-router-dom";

interface Props {
  navigationMenu: Menu[];
  focus: string;
  setFocus: (focus: string) => void;
}

function Navigation({ navigationMenu, focus, setFocus }: Props) {
  const history = useHistory();

  return (
    <div className={styles.nav1}>
      {navigationMenu.map((item, index) => {
        if (item.menuItems.length) {
          return (
            <div key={index}>
              <div
                className={styles.selectWrapper}
                onMouseOver={() => {
                  setFocus(index.toString());
                }}
              >
                <div className={styles.solutions}>{item.title}</div>
                <div className={styles.arrowIconContainer}>
                  <img
                    src={chevronDown}
                    alt=""
                    className={focus === index.toString() ? styles.iconRotated : styles.icon}
                  />
                </div>
              </div>
              <div
                className={styles.dropdownSolutions}
                style={{ display: focus === index.toString() ? "block" : "none" }}
              >
                <FlexContainer flexDirection="column">
                  {item.menuItems.map((e) => (
                    <div className={styles.option} key={index + e.itemLink} onClick={() => history.push(e.itemLink)}>
                      {e.itemTitle}
                    </div>
                  ))}
                </FlexContainer>
              </div>
            </div>
          );
        } else {
          return (
            <div className={styles.contact} onClick={() => history.push(item.link)}>
              {item.title}
            </div>
          );
        }
      })}
    </div>
  );
}

export default Navigation;
