import React, { useEffect, useState } from 'react';
import { getNavigationMenu } from '../../services/menu';

interface MenuItem {
  [key: string]: any; 
}

interface MenuData {
  schemaId: string;
  title: string;
  link: string;
  'menu-items': MenuItem[]; 
  schemaName: string;
}

interface SitemapItem {
  url: string;
  changefreq: string;
  priority: number;
}

function SitemapGenerator() {
  const [sitemap, setSitemap] = useState<string>('');

  useEffect(() => {
    const generateSitemap = async () => {
      try {
        const menuResponse = await getNavigationMenu();

        if (menuResponse.status !== 200) {
          console.error('Error fetching navigation menu:', menuResponse.status);
          return;
        }

        const menu: MenuData[] = menuResponse.body.data.menu.en;
        const baseUrl = 'https://ravent.com';

        const generateUrls = (menuItems: MenuItem[]): SitemapItem[] => {
          const urls: SitemapItem[] = [];

          for (const item of menuItems) {
            if (item['item-link']) {
              const url = baseUrl + item['item-link'];

              urls.push({
                url,
                changefreq: 'daily',
                priority: 0.7,
              });
            }

            if (item['menu-items'] && item['menu-items'].length > 0) {
              const subUrls = generateUrls(item['menu-items']);
              urls.push(...subUrls);
            }
          }

          return urls;
        };

        const sitemapData: SitemapItem[] = generateUrls(menu);

        const sitemapXML = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${sitemapData
    .map(
      (item) => `
    <url>
      <loc>${item.url}</loc>
      <changefreq>${item.changefreq}</changefreq>
      <priority>${item.priority}</priority>
    </url>`
    )
    .join('')}
</urlset>`;

        setSitemap(sitemapXML);
      } catch (error) {
        console.error('Error generating sitemap:', error);
      }
    };

    generateSitemap();
  }, []);

  return (
    <div>
      <h1>Sitemap</h1>
      <pre>{sitemap}</pre>
    </div>
  );
}

export default SitemapGenerator;
