import { stylesheet } from "typestyle";

export interface CardTheme {
  background: string;
  border: string;
}

export const whiteColors: CardTheme = {
  background: "#FFF",
  border: "1px solid #dddddd"
};

export const blueColors: CardTheme = {
  background: "#1D264E",
  border: "1px solid ##1D264E"
};

export const redColors: CardTheme = {
  background: "linear-gradient(270deg, #FF7D00 -56.62%, #E640FF 169.61%)",
  border: "none"
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function applyCardTheme(theme: CardTheme) {
  return {
    background: theme.background,
    width: "100%",
    height: "100%",
    minWidth: "auto",
    minHeight: "auto",
    borderRadius: "17px"
  };
}

const styles = stylesheet({
  cardStyle: {
    width: "100%",
    height: "100%",
    minWidth: "auto",
    minHeight: "auto",
    padding: "1.8em",
    borderRadius: "5px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        padding: "1.8em"
      }
    }
  },
  cursor: {
    cursor: "pointer"
  },

  border: {
    height: "100%",
    minWidth: "auto",
    minHeight: "auto",
    padding: "1.8em",
    borderRadius: "5px",
    border: "none !important"
  },
  buttonBlue: applyCardTheme(blueColors),
  buttonWhite: applyCardTheme(whiteColors),
  buttonRed: applyCardTheme(redColors)
});

export default styles;
