import React from "react";

import logo from "../../assets/logo.png";
import loginStyles from "./styles";
import mant from "../../assets/images/mant.png";
import { GridContainer } from "../../components/GridContainer/GridContainer";
import { useHistory } from "react-router-dom";

interface Props {}

const Maintenance: React.FC<Props> = () => {
  const history = useHistory();
  return (
    <div className={loginStyles.container}>
      <GridContainer columns="1fr 1fr" justifyContent="center" alignItems="center" gap="3em">
        <div className={loginStyles.imageContainer}>
          <img src={mant} alt="" className={loginStyles.image} />
        </div>
        <div className={loginStyles.inputsContainer}>
          <img src={logo} alt="logo" className={loginStyles.logo} />
          <div className={loginStyles.subtitle}>We are going under a scheduled maintainance</div>
          <div className={loginStyles.text}>
            We apologize for any inconveniences you may have. Please come back later !
          </div>

          <div className={loginStyles.footer}>
            <div>
              <span className={loginStyles.footerText}>
                If you need further assistance please 
                <span onClick={() => history.push("/contact-us")} className={loginStyles.link}>
                  contact us
                </span>
              </span>
            </div>
          </div>
        </div>
      </GridContainer>
    </div>
  );
};

export default Maintenance;
