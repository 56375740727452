import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import Footer from "../../components/Footer";

import styles from "./styles";
import { getCookiesPolicy } from "../../services/squidex";
import { CookiesPolicyResponse, LanguageCode } from "../../utils/types";
import { LanguageStore, useLanguageStore } from "../../store/languageStore";

const CookiesPolicy: React.FC = () => {
  const language: LanguageCode = useLanguageStore((state: LanguageStore) => state.language);
  const [data, setData] = useState<CookiesPolicyResponse>();
  const history = useHistory();

  const refFrontPage = useRef<HTMLDivElement>(null);
  const refContact = useRef<HTMLDivElement>(null);

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current != null) {
      history.push("/");
      ref.current.scrollIntoView();
    }
  };

  const fetchData = async () => {
    try {
      const res = await getCookiesPolicy();
      setData(res);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [language]);

  return (
    <div ref={refFrontPage}>
      <Header
        openMobile={() => history.push("/menu")}
        onClick={() => executeScroll(refContact)}
        onClickLogo={() => history.push("/")}
      />
      <div className={styles.container}>
        <h2 className={styles.title}>{data?.title[language]}</h2>
        <p>{data?.text[language]}</p>

        {data?.content &&
          data.content[language].map((item) => (
            <div key={item.title}>
              <h3 className={styles.subtitle}>{item.title}</h3>
              <p>{item.text}</p>
              {item.extra &&
                item.extra.map((element) => (
                  <div key={element.title}>
                    <h4 className={styles.cookieTitle}>{element.title}</h4>
                    {element.text.split("|").map((cookieElement) => {
                      const cookie = cookieElement.split(":");
                      return (
                        <p className={styles.cookieText} key={cookie[0]}>
                          <strong>{cookie[0]}:</strong> {cookie[1]}
                        </p>
                      );
                    })}
                  </div>
                ))}
            </div>
          ))}
      </div>
      <div ref={refContact}>
        <Footer />
      </div>
    </div>
  );
};

export default CookiesPolicy;
