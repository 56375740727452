import React, { useRef } from "react";
import styles from "./styles";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import Footer from "../../components/Footer";
import check from "../../assets/icons/check.svg";

const ThankYou: React.FC = () => {
  const refFrontPage = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const refContact = useRef<HTMLDivElement>(null);

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current != null) {
      history.push("/");
      ref.current.scrollIntoView();
    }
  };
  return (
    <div ref={refFrontPage}>
      <Header
        openMobile={() => history.push("/menu")}
        onClick={() => executeScroll(refContact)}
        onClickLogo={() => history.push("/")}
      />
      <div className={styles.background}>
        <div className={styles.infoContainer}>
          <h2 className={styles.infoContainerTitle}>Try & Buy our Platform</h2>
          <p className={styles.infoContainerText}>
            Please let us have your contact information so that we schedule a Demo
          </p>
        </div>
        <div className={styles.cardContainer}>
          <img src={check} alt="Success" className={styles.cardContainerIcon} />
          <p className={styles.cardContainerText}>
            Thank you for joining this revolution! We will contact you with all the information you need.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ThankYou;
