import { stylesheet } from "typestyle";

const style = stylesheet({
  header: {
    width: "100%",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    background: "#fff",
    top: 0,
    borderBottom: "1px solid #DCE1E6",
    height: "88px",
    left: 0,
    padding: "1em 5em",
    zIndex: 99,
    $nest: {
      "@media screen and (max-width: 1000px)": {
        padding: "1em 2em"
      }
    }
  },

  headerMobile: {
    width: "100%",
    display: "none",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    background: "#fff",
    top: 0,
    left: 0,
    zIndex: 99,

    $nest: {
      "@media screen and (max-width: 1000px)": {
        display: "flex"
      }
    }
  },

  headerRotate: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    background: "#fff",
    top: 0,
    left: 0,
    zIndex: 99
  },

  wrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1rem",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        display: "none"
      },
      "&:hover": {
        color: "#246DF1"
      }
    }
  },
  logo: {
    cursor: "pointer",
    width: "10em",
    fontSize: "calc(14px + 4 * ((100vw - 320px) / 1200))"
  },
  contact: {
    fontSize: "16px",
    color: "#6c757d",
    padding: ".6em 1.8em",
    cursor: "pointer",
    whiteSpace: "nowrap",
    $nest: {
      "&:hover": {
        color: "#246DF1"
      }
    }
  },
  solutions: {
    fontSize: "16px",
    color: "#1D264E",
    whiteSpace: "nowrap",
    padding: "0 2rem 0 1rem",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    $nest: {
      "&:hover": {
        color: "#246DF1"
      }
    }
  },
  loginText: {
    background: "#fff",
    color: "#246DF1",
    height: "40px"
  },
  loginButton: {
    fontSize: "16px",
    background: "#fff",
    color: "#246DF1",
    border: "1px solid #246DF1",
    padding: "0em 2em",
    borderRadius: "48px",
    cursor: "pointer",
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "75%",
    minWidth: "10rem",
    margin: "1rem auto"
  },
  buttonContact: {
    fontSize: "16px",
    background: "#246DF1",
    color: "#fff",
    padding: "0em 1em",
    height: "2.5rem",
    borderRadius: "48px",
    cursor: "pointer",
    width: "75%",
    minWidth: "10rem",
    margin: "1rem auto"
  },
  selectWrapper: {
    position: "relative",
    width: "100%",
    color: "rgba(29, 38, 78, 1)"
  },
  option: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
    padding: "1.25em 0.5em",
    height: "2.3em",
    fontSize: "18px",
    background: "#fff",
    color: "rgba(29, 38, 78, 1)",
    $nest: {
      "&:hover": {
        color: "#246DF1"
      }
    }
  },
  icon: {
    width: "1em"
  },
  iconMenu: {
    width: "2em"
  },
  iconRotate: {
    width: "2em"
  },
  quitIcon: {
    width: "1em"
  },
  iconRotated: {
    width: "1em",
    transform: "rotate(180deg)"
  },
  arrowIconContainer: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    right: 0,
    height: "100%",
    width: "2.7em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "1em"
  },
  wrapperMobile: {
    padding: "1em",
    backgroundColor: "#fff",
    height: "100%"
  },
  navMenu: {
    display: "flex"
  },
  buttons: {
    display: "flex",
    gap: "1rem"
  },
  language: {
    display: "flex",
    textTransform: "uppercase",
    gap: "0.5rem",
    fontSize: "18px",
    alignItems: "center",
    color: "black",
    cursor: "pointer",
    position: "relative",
    userSelect: "none"
  },
  languageModal: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "175%",
    right: "0",
    zIndex: 2,
    backgroundColor: "#ffffff",
    textTransform: "capitalize",
    padding: "1rem 0 1rem 1rem",
    width: "9rem",
    boxShadow: "-3px 3px 13px 0px #AFAFAF59",
    borderRadius: "5px",

    $nest: {
      p: {
        paddingBlock: "0.5rem",

        $nest: {
          "&:hover": {
            color: "#246DF1bb"
          }
        }
      }
    }
  },
  languageSelected: {
    color: "#246DF1"
  },
  dropdownSolutions: {
    background: "white",
    position: "absolute",
    top: "calc(4em + 21px)",
    boxShadow: " 0px 0px 2px 1px rgba(235, 235, 235, 1)",
    borderRadius: 2,
    overflow: "hidden",
    scrollPaddingTop: "2em",
    overflowY: "scroll",
    zIndex: 3,
    marginLeft: "1.5em",
    width: "fit-content",
    fontSize: "1rem"
  },
  linkContainer: {
    display: "flex",
    padding: "1rem",
    gap: "2rem"
  },
  linksSection: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "10rem",
    overflow: "hidden",
    flexWrap: "wrap"
  },
  section: {
    display: "flex",
    flexDirection: "column",

    $nest: {
      p: {
        marginBottom: "1rem",
        padding: "0.5rem",
        textTransform: "uppercase"
      },
      a: {
        padding: "0.5rem",
        color: "black",
        paddingRight: "2rem",

        $nest: {
          "&:hover": {
            backgroundColor: "#E9F5FF"
          }
        }
      }
    }
  },
  nav1: {
    display: "flex"
  }
});

export default style;
