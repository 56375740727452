import { stylesheet } from "typestyle";
import { black, clearBlue } from "../../utils/colors";

const loginStyles = stylesheet({
  container: {
    width: "100%",
    height: "100vh",
    background: "#F6F9FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  elementToTransform: {
    position: "absolute",
    width: "50%"
    // transform: "translate(-50%, -50%)" // Move the element 50% from the center horizontally and vertically
  },
  qrCode: {
    width: "140px"
  },
  imageContainer: {
    width: "100%"
  },
  emailContainer: {
    marginRight: "2em"
  },
  button: { width: "133px", margin: "0 1em" },
  image: {
    width: "290px"
  },
  link: {
    textDecoration: "underline",
    color: clearBlue,
    cursor: "pointer"
  },
  footer: {
    fontSize: "0.8em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.4em",
    color: clearBlue,
    padding: "30% 0 0 0",
    $nest: {
      "& > a": {
        color: "currentColor"
      }
    }
  },
  title: {
    fontSize: "2.5em",
    fontWeight: "bold",
    paddingBottom: "1em"
  },
  blue: {
    color: "#1D264E"
  },

  subtitle: {
    fontSize: "2.2em",
    fontWeight: "bold",
    padding: "2em 0 0.5em 0",
    textAlign: "center",
    color: "#1D264E"
  },
  text: {
    textAlign: "center",
    padding: "0 0 1.5em 0",
    color: "#1D264E"
  },
  inputsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%"
  },
  error: {
    minHeight: "2em",
    color: "red",
    textAlign: "center",
    marginTop: "1em"
  },

  logo: {
    height: "29px",
    margin: "0 auto",
    display: "block"
  },
  footerText: {
    color: black
  }
});

export default loginStyles;
