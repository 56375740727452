import React from "react";
import styles from "./styles";
import { Card } from "../Card";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import readmore from "../../assets/readmore.svg";

export interface Props {
  title: string;
  info: string;
  onClickLink: () => void;
}

export const InfoCarrousel: React.FC<Props> = ({ title, info, onClickLink }) => {
  return (
    <Card boxShadow="0px 1px 12px 0px rgba(0, 0, 0, 0.04)" theme="white">
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.textSmall2}>{info}</div>

        <div style={{ cursor: "pointer", paddingTop: "clamp(1em, 5vw, 1em)" }} onClick={onClickLink}>
          <FlexContainer justifyContent="flex-start" alignItems="center">
            <img src={readmore} alt="Read more" className={styles.icon} />
            <div className={styles.readMore}>Read more</div>
          </FlexContainer>
        </div>
      </div>
    </Card>
  );
};
