import axios from "axios";
import pkg from 'lodash';
const { get } = pkg;

export function parseMenu(data) {
  return {
    title: get(data, "title", ""),
    id: get(data, "schemaId", ""),
    menuItems: get(data, "menu-items", []).map(parseMenuItem),
    link: get(data, "link", "")
  };
}

export function parseMenuItem(data) {
  return {
    itemLink: get(data, "item-link", ""),
    itemTitle: get(data, "item-title", "")
  };
}

export function parseMenuResponse(data) {
  return {
    en: get(data, "data.menu.en", []).map(parseMenu),
    es: get(data, "data.menu.es", []).map(parseMenu),
    de: get(data, "data.menu.de", []).map(parseMenu)
  };
}

const tokenEndpoint = "https://cms.ravent.com/identity-server/connect/token";
export const getSquidexToken = async () => {
  try {
    const data = new URLSearchParams();
    data.append("grant_type", "client_credentials");
    data.append("client_id", "ravent-landing-page:default");
    data.append("client_secret", "o2twrbfyhtejxozbrxrga6wxljyvs3fpge3afg6p6fmx");
    data.append("scope", "squidex-api");
    data.append("X-Unpublished", "1");

    const response = await axios.post(tokenEndpoint, data);

    const accessToken = response.data.access_token;
    return accessToken;
  } catch (error) {
    console.error("Error getting the access token:", error);
    throw error;
  }
};

export async function getNavigationMenu() {
  const token = await getSquidexToken();

  const url = "https://cms.ravent.com/api/content/ravent-landing-page/navigation/3410d4a6-7e77-41a1-889c-1bec5e05a6f3"; // Replace with your actual URL
  const headers = {
    Authorization: `Bearer ${token}` // Add your token authorization here
  };


  try {
    const res = await fetch(url, { method: "GET", headers });

    if (res.ok) {
      const json = await res.json(); // Parse the response JSON
      return { status: res.status, body: json };
    } else {
      const json = await res.json(); // Parse the error JSON
      throw new Error(json.error);
    }
  } catch (error) {
    console.error("Error fetching navigation menu:", error);
    throw error; // Rethrow the error for handling higher up the call stack
  }
}
