import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import {
  Asset,
  ButtonContent,
  Carrousel,
  CarrouselResponse,
  CompaniesResponse,
  Company,
  ContactResponse,
  Content,
  ContentRow,
  CookiesPolicyResponse,
  CopyrightResponse,
  FunctionalityResponse,
  HomeContent,
  HomeContentImage,
  HomeFooter,
  HomeResponse,
  IntegrationContent,
  Language,
  LanguagesResponse,
  Legal,
  Menu,
  MenuItem,
  MenuResponse,
  RaventFunctionality,
  SocialNetwork,
  SocialNetworksResponse,
  TextContent,
  TitleContent,
  TryAndBuyContent,
  UpperButton,
  UpperButtonsResponse
} from "../utils/types";
import { get } from "lodash";
import fetch from "../utils/fetch";
import NavigationData from "../utils/navigationTypes";
import { JwtPayload, jwtDecode } from "jwt-decode";

const tokenEndpoint = "https://cms.ravent.com/identity-server/connect/token";
// const squidexApiCarrousel = "https://cloud.squidex.io/api/content/ravent-landing-page/carruseles"; // Replace with your app name

export const getSquidexToken = async () => {
  try {
    const data = new URLSearchParams();
    data.append("grant_type", "client_credentials");
    data.append("client_id", "ravent-landing-page:default");
    data.append("client_secret", "o2twrbfyhtejxozbrxrga6wxljyvs3fpge3afg6p6fmx");
    data.append("scope", "squidex-api");
    data.append("X-Unpublished", "1");

    const token = secureLocalStorage.getItem("token") as string;
    const currentTime = Date.now() / 1000;
    const decodeToken = token && jwtDecode<JwtPayload>(token);
    const validToken = decodeToken ? Number(decodeToken.exp) > currentTime : false;

    if (!validToken) {
      const response = await axios.post(tokenEndpoint, data);
      const accessToken = response.data.access_token;
      secureLocalStorage.setItem("token", accessToken);
      return accessToken;
    } else {
      return token;
    }
  } catch (error) {
    console.error("Error getting the access token:", error);
    throw error;
  }
};

export function parseCarrousel(data?: unknown): Carrousel {
  return {
    id: get(data, "data.title.en", ""),
    createdBy: get(data, "createdBy", ""),
    data: {
      title: {
        es: get(data, "data.title.es", ""),
        en: get(data, "data.title.en", ""),
        de: get(data, "data.title.de", "")
      },
      slurp: {
        es: get(data, "data.slurp.es", ""),
        en: get(data, "data.slurp.en", ""),
        de: get(data, "data.slurp.de", "")
      },
      link: {
        es: get(data, "data.link.es", ""),
        en: get(data, "data.link.en", ""),
        de: get(data, "data.link.de", "")
      },
      linkText: {
        es: get(data, "data.link-text.es", ""),
        en: get(data, "data.link-text.en", ""),
        de: get(data, "data.link-text.de", "")
      },
      status: get(data, "data.status", ""),
      image: get(data, "data.image.iv.[0]", ""),
      text: {
        es: get(data, "data.text.es", ""),
        en: get(data, "data.text.en", ""),
        de: get(data, "data.text.de", "")
      }
    }
  };
}

export const defaultContent: Content = {
  text: {
    es: "",
    en: "",
    de: ""
  },
  name: {
    es: "",
    en: "",
    de: ""
  },
  heading: {
    es: "",
    en: "",
    de: ""
  },
  title: {
    es: "",
    en: "",
    de: ""
  },
  footerButton: {
    en: {
      label: "",
      link: ""
    },
    es: { label: "", link: "" },
    de: { label: "", link: "" }
  },
  headerButton: {
    en: {
      label: "",
      link: ""
    },
    es: { label: "", link: "" },
    de: { label: "", link: "" }
  },
  footerText: {
    es: "",
    en: "",
    de: ""
  },
  footerTitle: {
    es: "",
    en: "",
    de: ""
  },
  id: "",
  image: {
    es: {
      isVideo: false,
      image: "",
      description: ""
    },
    en: {
      isVideo: false,
      image: "",
      description: ""
    },
    de: {
      isVideo: false,
      image: "",
      description: ""
    }
  },
  bodyContents: {
    es: [],
    en: [],
    de: []
  },
  footerCarrousel: {
    es: [],
    en: [],
    de: []
  }
};

export function parseText(data?: unknown): TextContent {
  return {
    text: get(data, "text", ""),
    title: get(data, "title", ""),
    asset: {
      image: get(data, "assets[0].file[0]", ""),
      isVideo: get(data, "assets[0].isVideo", false),
      description: get(data, "assets[0].description", "")
    }
  };
}

export function parseContent(data?: unknown): Content {
  return {
    id: get(data, "id", ""),
    heading: {
      es: get(data, "data.heading.es", ""),
      en: get(data, "data.heading.en", ""),
      de: get(data, "data.heading.de", "")
    },
    title: {
      es: get(data, "data.title.es", ""),
      en: get(data, "data.title.en", ""),
      de: get(data, "data.title.de", "")
    },
    footerButton: {
      es: {
        link: get(data, "data.footer-button.es.link", ""),
        label: get(data, "data.footer-button.es.label", "")
      },
      en: {
        link: get(data, "data.footer-button.en.link", ""),
        label: get(data, "data.footer-button.en.label", "")
      },
      de: {
        link: get(data, "data.footer-button.de.link", ""),
        label: get(data, "data.footer-button.de.label", "")
      }
    },
    headerButton: {
      es: {
        link: get(data, "data.header-button.es.link", ""),
        label: get(data, "data.header-button.es.label", "")
      },
      en: {
        link: get(data, "data.header-button.en.link", ""),
        label: get(data, "data.header-button.en.label", "")
      },
      de: {
        link: get(data, "data.header-button.de.link", ""),
        label: get(data, "data.header-button.de.label", "")
      }
    },

    footerText: {
      es: get(data, "data.footer-text.es", ""),
      en: get(data, "data.footer-text.en", ""),
      de: get(data, "data.footer-text.de", "")
    },
    footerTitle: {
      es: get(data, "data.footer-title.es", ""),
      en: get(data, "data.footer-title.en", ""),
      de: get(data, "data.footer-title.de", "")
    },
    image: {
      es: {
        image: get(data, "data.asset.es.file[0]", ""),
        isVideo: get(data, "data.asset.es.isVideo", false),
        description: get(data, "data.asset.es.desription", "")
      },
      en: {
        image: get(data, "data.asset.en.file[0]", ""),
        isVideo: get(data, "data.asset.en.isVideo", false),
        description: get(data, "data.asset.en.desription", "")
      },
      de: {
        image: get(data, "data.asset.de.file[0]", ""),
        isVideo: get(data, "data.asset.de.isVideo", false),
        description: get(data, "data.asset.de.desription", "")
      }
    },
    name: {
      es: get(data, "data.name.es", ""),
      en: get(data, "data.name.en", ""),
      de: get(data, "data.name.de", "")
    },
    bodyContents: {
      en: get(data, "data.body-contents.en", []).map(parseText),
      es: get(data, "data.body-contents.es", []).map(parseText),
      de: get(data, "data.body-contents.de", []).map(parseText)
    },

    footerCarrousel: {
      en: get(data, "data.footer-carrousel.en", []).map(parseText),
      es: get(data, "data.footer-carrousel.es", []).map(parseText),
      de: get(data, "data.footer-carrousel.de", []).map(parseText)
    },

    text: {
      es: get(data, "data.text.es", ""),
      en: get(data, "data.text.en", ""),
      de: get(data, "data.text.de", "")
    }
  };
}

export function parseCarrouselResponse(data?: unknown): CarrouselResponse {
  return {
    total: get(data, "total", 0),
    items: get(data, "items", []).map(parseCarrousel)
  };
}

export async function getCarrouselData(): Promise<CarrouselResponse> {
  const token = await getSquidexToken();
  const res = await fetch(
    "/content/ravent-landing-page/carruseles?q=%7B%22filter%22%3A%7B%22path%22%3A%20%22data.tags.iv%22%2C%22op%22%3A%22eq%22%2C%22value%22%3A%22usecases%22%7D%7D",
    token
  );
  return {
    items: res.body ? res.body.items.map(parseCarrousel) : [],
    total: get(res.body, "total", 0)
  };
}

export async function getImage(): Promise<string> {
  const token = await getSquidexToken();
  const res = await fetch("/carruseles", token);
  return res.body;
}

export async function getContent(id: string): Promise<Content> {
  const token = await getSquidexToken();
  const res = await fetch(`/content/ravent-landing-page/landing-usecases-array/${id}`, token);

  return parseContent(res.body);
}

export async function getSolutionsCustomerContent(): Promise<Content> {
  const token = await getSquidexToken();
  const res = await fetch(
    `/content/ravent-landing-page/landing-solutions-array/72027ee1-4d70-45d7-bb0b-6024c5363bac`,
    token
  );

  return parseContent(res.body);
}

export async function getTeamSolutionsContent(): Promise<Content> {
  const token = await getSquidexToken();
  const res = await fetch(
    `/content/ravent-landing-page/landing-solutions-array/c1774c91-2c22-40cd-b9fd-be45b2db5f7c`,
    token
  );

  return parseContent(res.body);
}

export function parseMenu(data?: unknown): Menu {
  return {
    title: get(data, "title", ""),
    id: get(data, "schemaId", ""),
    menuItems: get(data, "menu-items", []).map(parseMenuItem),
    link: get(data, "link", "")
  };
}

export function parseMenuItem(data?: unknown): MenuItem {
  return {
    itemLink: get(data, "item-link", ""),
    itemTitle: get(data, "item-title", "")
  };
}

export function parseMenuResponse(data?: unknown): MenuResponse {
  return {
    en: get(data, "data.menu.en", []).map(parseMenu),
    es: get(data, "data.menu.es", []).map(parseMenu),
    de: get(data, "data.menu.de", []).map(parseMenu)
  };
}

async function getNavigationData(): Promise<NavigationData> {
  const navigationData = JSON.parse(localStorage.getItem("navigationData") as string);

  if (!navigationData) {
    const token = await getSquidexToken();
    const res = await fetch(`/content/ravent-landing-page/navigation/3410d4a6-7e77-41a1-889c-1bec5e05a6f3`, token);
    localStorage.setItem("navigationData", JSON.stringify(res.body));
    return res.body;
  } else {
    return navigationData;
  }
}

export async function getNavigationMenu(): Promise<MenuResponse> {
  const navigationData = await getNavigationData();
  return parseMenuResponse(navigationData);
}

export function parseCompany(data?: unknown): Company {
  return {
    id: get(data, "schemaId"),
    region: get(data, "region"),
    name: get(data, "name"),
    url: get(data, "url"),
    addressLink: get(data, "address-link"),
    addressl1: get(data, "address-l1"),
    addressl2: get(data, "address-l2"),
    phone: get(data, "phone")
  };
}

export function parseCompaniesResponse(data?: unknown): CompaniesResponse {
  return {
    en: get(data, "data.companies.en", []).map(parseCompany),
    es: get(data, "data.companies.es", []).map(parseCompany),
    de: get(data, "data.companies.de", []).map(parseCompany)
  };
}

export async function getCompanies(): Promise<CompaniesResponse> {
  const navigationData = await getNavigationData();
  return parseCompaniesResponse(navigationData);
}

export function parseCopyrightResponse(data?: unknown): CopyrightResponse {
  return {
    en: get(data, "data.copyright.en", ""),
    es: get(data, "data.copyright.es", ""),
    de: get(data, "data.copyright.de", "")
  };
}

export async function getCopyright(): Promise<CopyrightResponse> {
  const navigationData = await getNavigationData();
  return parseCopyrightResponse(navigationData);
}

export function parseSocialNetwork(data?: unknown): SocialNetwork {
  return {
    id: get(data, "schemaId"),
    name: get(data, "name"),
    link: get(data, "link"),
    icon: get(data, "icon", [])
  };
}

export function parseSocialNetworksResponse(data?: unknown): SocialNetworksResponse {
  return {
    iv: get(data, "data.social-networks.iv", []).map(parseSocialNetwork)
  };
}

export async function getSocialNetworks(): Promise<SocialNetworksResponse> {
  const navigationData = await getNavigationData();
  return parseSocialNetworksResponse(navigationData);
}

export function parseUpperButton(data?: unknown): UpperButton {
  return {
    id: get(data, "schemaId"),
    link: get(data, "link"),
    label: get(data, "label")
  };
}

export function parseUpperButtonResponse(data?: unknown): UpperButtonsResponse {
  return {
    en: get(data, "data.upper-buttons.en", []).map(parseUpperButton),
    es: get(data, "data.upper-buttons.es", []).map(parseUpperButton),
    de: get(data, "data.upper-buttons.de", []).map(parseUpperButton)
  };
}

export async function getUpperButtons(): Promise<UpperButtonsResponse> {
  const navigationData = await getNavigationData();
  return parseUpperButtonResponse(navigationData);
}

export function parseLanguage(data?: unknown): Language {
  return {
    name: get(data, "name"),
    code: get(data, "code")
  };
}

export function parseLanguageResponse(data?: unknown): LanguagesResponse {
  return {
    en: get(data, "data.languages.en", []).map(parseLanguage),
    es: get(data, "data.languages.es", []).map(parseLanguage),
    de: get(data, "data.languages.de", []).map(parseLanguage)
  };
}

export async function getLanguages(): Promise<LanguagesResponse> {
  const navigationData = await getNavigationData();
  return parseLanguageResponse(navigationData);
}

export function parseHomeContent(data?: unknown): HomeContent {
  return {
    title: get(data, "title"),
    text: get(data, "text")
  };
}

export function parseHomeContentImage(data?: unknown): HomeContentImage {
  return {
    image: get(data, "assets"),
    text: get(data, "text")
  };
}

export function parseHomeFooter(data?: unknown): HomeFooter {
  return {
    title: get(data, "title"),
    text: get(data, "text"),
    button: get(data, "buttons")
  };
}

export function parseHomeResponse(data?: unknown): HomeResponse {
  return {
    content: {
      en: parseHomeContent(get(data, "data.header.en")),
      es: parseHomeContent(get(data, "data.header.es")),
      de: parseHomeContent(get(data, "data.header.de"))
    },
    contentImage1: {
      en: parseHomeContentImage(get(data, "data.contentImage1.en")),
      es: parseHomeContentImage(get(data, "data.contentImage1.es")),
      de: parseHomeContentImage(get(data, "data.contentImage1.de"))
    },
    contentImage2: {
      en: parseHomeContentImage(get(data, "data.contentImage2.en")),
      es: parseHomeContentImage(get(data, "data.contentImage2.es")),
      de: parseHomeContentImage(get(data, "data.contentImage2.de"))
    },
    homeFooter: {
      en: parseHomeFooter(get(data, "data.footer.en")),
      es: parseHomeFooter(get(data, "data.footer.es")),
      de: parseHomeFooter(get(data, "data.footer.de"))
    }
  };
}

export async function getHome(): Promise<HomeResponse> {
  const token = await getSquidexToken();
  const res = await fetch("/content/ravent-landing-page/home", token);

  return parseHomeResponse(res.body.items[0]);
}

export function parseFunctionality(data?: unknown): RaventFunctionality {
  return {
    id: get(data, "data.title.en", ""),
    text: {
      es: get(data, "data.text.es", ""),
      en: get(data, "data.text.en", ""),
      de: get(data, "data.text.de", "")
    },
    title: {
      es: get(data, "data.title.es", ""),
      en: get(data, "data.title.en", ""),
      de: get(data, "data.title.de", "")
    },
    image: {
      es: get(data, "data.image.iv.[0]", ""),
      en: get(data, "data.image.iv.[0]", ""),
      de: get(data, "data.image.iv.[0]", "")
    }
  };
}

export function parseTitleContent(data?: unknown): TitleContent {
  return {
    subtitle: {
      es: get(data, "data.subtitle.es", ""),
      en: get(data, "data.subtitle.en", ""),
      de: get(data, "data.subtitle.de", "")
    },
    text: {
      es: get(data, "data.text.es", ""),
      en: get(data, "data.text.en", ""),
      de: get(data, "data.text.de", "")
    },
    title: {
      es: get(data, "data.title.es", ""),
      en: get(data, "data.title.en", ""),
      de: get(data, "data.title.de", "")
    },
    footerButton: {
      es: {
        link: get(data, "data.footer-button.es.link", ""),
        label: get(data, "data.footer-button.es.label", "")
      },
      en: {
        link: get(data, "data.footer-button.en.link", ""),
        label: get(data, "data.footer-button.en.label", "")
      },
      de: {
        link: get(data, "data.footer-button.de.link", ""),
        label: get(data, "data.footer-button.de.label", "")
      }
    },
    footerText: {
      es: get(data, "data.footer-text.es", ""),
      en: get(data, "data.footer-text.en", ""),
      de: get(data, "data.footer-text.de", "")
    },
    footerTitle: {
      es: get(data, "data.footer-title.es", ""),
      en: get(data, "data.footer-title.en", ""),
      de: get(data, "data.footer-title.de", "")
    },
    image: get(data, "data.image.iv.[0]", "")
  };
}

export function parseTryBuyContent(data?: unknown): TryAndBuyContent {
  return {
    es: {
      id: get(data, "data.form.es[0].id", ""),
      src: get(data, "data.form.es[0].url", ""),
      title: get(data, "data.form.es[0].title", "")
    },
    en: {
      id: get(data, "data.form.en[0].id", ""),
      src: get(data, "data.form.en[0].url", ""),
      title: get(data, "data.form.en[0].title", "")
    },
    de: {
      id: get(data, "data.form.de[0].id", ""),
      src: get(data, "data.form.de[0].url", ""),
      title: get(data, "data.form.de[0].title", "")
    },
    text: {
      es: get(data, "data.text.es", ""),
      en: get(data, "data.text.en", ""),
      de: get(data, "data.text.de", "")
    },
    title: {
      es: get(data, "data.title.es", ""),
      en: get(data, "data.title.en", ""),
      de: get(data, "data.title.de", "")
    }
  };
}

export async function getFunctionality(): Promise<FunctionalityResponse> {
  const token = await getSquidexToken();
  const res = await fetch(
    "/content/ravent-landing-page/carruseles?q=%7B%22filter%22%3A%7B%22path%22%3A%20%22data.tags.iv%22%2C%22op%22%3A%22eq%22%2C%22value%22%3A%22functionality%22%7D%7D",
    token
  );
  return {
    items: res.body ? res.body.items.map(parseFunctionality) : [],
    total: get(res.body, "total", 0)
  };
}

export async function getFunctionalityContent(): Promise<Content> {
  const token = await getSquidexToken();
  const res = await fetch(
    `/content/ravent-landing-page/landing-functionality-array/68f812de-a13d-4e62-9174-7d258cb89399`,
    token
  );

  return parseContent(res.body);
}

export async function getLegalTerms(): Promise<Legal> {
  const token = await getSquidexToken();
  const res = await fetch(`/content/ravent-landing-page/legal/872c8cc4-8395-437e-8935-7c866bbad306`, token);

  return parseLegal(res.body);
}

export async function getPrivacy(): Promise<Legal> {
  const token = await getSquidexToken();
  const res = await fetch(`/content/ravent-landing-page/legal/8054de4d-d0d7-4161-96d9-a1cfbabedece`, token);

  return parseLegal(res.body);
}

export async function getImpress(): Promise<Legal> {
  const token = await getSquidexToken();
  const res = await fetch(`/content/ravent-landing-page/legal/6687da57-05aa-4d33-b0f9-4367891c30d4`, token);

  return parseLegal(res.body);
}

export async function getTryAndBuyContent(): Promise<TryAndBuyContent> {
  const token = await getSquidexToken();
  const res = await fetch(`/content/ravent-landing-page/try-and-buy/17c11094-6f99-492f-968e-2bc116764d83`, token);

  return parseTryBuyContent(res.body);
}

export async function getAboutUsContent(): Promise<TitleContent> {
  const token = await getSquidexToken();
  const res = await fetch(`/content/ravent-landing-page/aboutus/bcd36efc-5488-4dc4-ac93-7dad7c71e54b`, token);

  return parseTitleContent(res.body);
}

export function parseLegal(data?: unknown): Legal {
  return {
    text: {
      es: get(data, "data.text.es", ""),
      en: get(data, "data.text.en", ""),
      de: get(data, "data.text.de", "")
    },
    name: {
      es: get(data, "data.name.es", ""),
      en: get(data, "data.name.en", ""),
      de: get(data, "data.name.de", "")
    },
    title: {
      es: get(data, "data.title.es", ""),
      en: get(data, "data.title.en", ""),
      de: get(data, "data.title.de", "")
    },
    country: {
      es: get(data, "data.country.es", ""),
      en: get(data, "data.country.en", ""),
      de: get(data, "data.country.de", "")
    }
  };
}

export function parseAssets(data?: unknown): Asset {
  return {
    description: get(data, "description", ""),
    isVideo: get(data, "isVideo", ""),
    image: get(data, "file[0]", "")
  };
}

export function parseButton(data?: unknown): ButtonContent {
  return {
    label: get(data, "label", ""),
    link: get(data, "link", "")
  };
}

export function parseContentRow(data?: unknown): ContentRow {
  return {
    title: get(data, "title", ""),
    text: get(data, "text", ""),
    assets: get(data, "assets", []).map(parseAssets),
    buttons: get(data, "buttons", []).map(parseButton)
  };
}

export function parseIntegrationContent(data?: unknown): IntegrationContent {
  return {
    footer: {
      es: parseContentRow(get(data, "data.footer.es", "")),
      de: parseContentRow(get(data, "data.footer.de", "")),
      en: parseContentRow(get(data, "data.footer.en", ""))
    },
    name: get(data, "data.name", ""),
    header: {
      es: parseContentRow(get(data, "data.header.es", "")),
      de: parseContentRow(get(data, "data.header.de", "")),
      en: parseContentRow(get(data, "data.header.en", ""))
    },
    currentIntegrations: {
      es: parseContentRow(get(data, "data.current-integrations.es", "")),
      de: parseContentRow(get(data, "data.current-integrations.de", "")),
      en: parseContentRow(get(data, "data.current-integrations.en", ""))
    },
    futureIntegrations: {
      es: parseContentRow(get(data, "data.future-integrations.es", "")),
      de: parseContentRow(get(data, "data.future-integrations.de", "")),
      en: parseContentRow(get(data, "data.future-integrations.en", ""))
    },
    raventActions: {
      es: parseContentRow(get(data, "data.ravent-actions.es", "")),
      de: parseContentRow(get(data, "data.ravent-actions.de", "")),
      en: parseContentRow(get(data, "data.ravent-actions.en", ""))
    }
  };
}

export async function getIntegrationContent(): Promise<IntegrationContent> {
  const token = await getSquidexToken();
  const res = await fetch(`/content/ravent-landing-page/integrations/9be3a20b-053d-4e9c-96e7-4061f33825cd`, token);

  return parseIntegrationContent(res.body);
}

export async function getContactUsContent(): Promise<TryAndBuyContent> {
  const token = await getSquidexToken();
  const res = await fetch(`/content/ravent-landing-page/contactus/503f7521-f531-4a74-8351-b51f067a8c68`, token);

  return parseTryBuyContent(res.body);
}

export async function getLegals(): Promise<Legal[]> {
  const token = await getSquidexToken();
  const res = await fetch("/content/ravent-landing-page/legal", token);

  return res.body ? res.body.items.map(parseLegal) : [];
}

export async function getCookiesPolicy(): Promise<CookiesPolicyResponse> {
  const token = await getSquidexToken();
  const res = await fetch("/content/ravent-landing-page/cookies-policy", token);

  return res.body.items[0].data;
}

export function parseContactResponse(data?: any): ContactResponse {
  return {
    contact: get(data, "data.contact.iv", "")
  };
}

export async function getContact(): Promise<ContactResponse> {
  const navigationData = await getNavigationData();
  return parseContactResponse(navigationData);
}
