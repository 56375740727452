import React from "react";
import { GridContainer } from "../GridContainer/GridContainer";
import Mobile from "./Mobile";
import { Card } from "../Card";
import ReactMarkdown from "react-markdown";

import styles from "./styles";
import { Button } from "../Button";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import { useHistory } from "react-router-dom";

interface Props {
  heading: string;
  title: string;
  name: string;
  image: string;
  button: string;
  text: string;
  buttonLink: string;
  isVideo: boolean;
  alt: string;
}

const UseCaseTextImage: React.FC<Props> = ({ alt, text, image, title, name, heading, button, buttonLink, isVideo }) => {
  const history = useHistory();
  return (
    <div>
      <div className={styles.mobileContainer}>
        <Mobile
          alt={alt}
          buttonLink={buttonLink}
          button={button}
          name={name}
          heading={heading}
          image={image}
          title={title}
          text={text}
          isVideo={isVideo}
        />
      </div>
      <div className={styles.container}>
        <Card theme="white">
          <div className={styles.content}>
            <GridContainer gap="1em" columns="1fr 1fr">
              <div style={{ padding: "0em 2em 1em 2em" }}>
                <div className={styles.name}>{name}</div>
                <ReactMarkdown className={styles.title}>{title}</ReactMarkdown>
                <ReactMarkdown className={styles.textSmall}>{text}</ReactMarkdown>
                <Button
                  onClick={() => history.push(buttonLink || "")}
                  buttonClassname={styles.download}
                  label={button}
                />
              </div>
              {isVideo ? (
                <video loop autoPlay className={styles.videoContainer}>
                  <source src={image} type="video/mp4" className={styles.imageWrapVideo} />
                </video>
              ) : (
                <div className={styles.imageContainer}>
                  <img src={image} alt={name} className={styles.imageWrap} />
                </div>
              )}
            </GridContainer>
            <FlexContainer>
              <div className={styles.heading}>{heading}</div>
            </FlexContainer>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default UseCaseTextImage;
