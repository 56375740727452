export const clearBlue = "#1fa0ff";
export const black = "#333333";
export const darkBlack = "#000000";

export const linkBlue = "#0ca4ff";
export const hoverList = "#f3f9fe";
export const bluishGrey = "#858d8f";
export const ceruleanBlue = "#0163e7";
export const azure = "#0163e7";
export const kellyGreen = "#00a334";
export const orangeyRed = "#ff2929";
export const lightGrey = "#dce1e6";
export const normalGray = "#666666";

export const yellowUI = "#faad15";

export const pointGreen = "#10A841";
export const pointGray = "#858D8F";
export const pointRed = "#FF2929";
export const pointBlue = "#246DF1";
export const pointYellow = "#FFB84E";
export const pointOrange = "#F37021";
export const pointBrown = "#782E2E";
export const pointPurple = "#6E28CD";
export const pointLightBlue = "#2CC6F6";
export const pointTurquoise = "#0FABB1";
export const pointFusia = "#B718C5";
export const pointPink = "#F16DA4";
