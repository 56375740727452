import React, { useState } from "react";
import { useCookies } from "react-cookie";

import styles from "./styles";
import { Switch } from "antd";
import { Link } from "react-router-dom";

const CookiesConsent: React.FC = () => {
  const [cookiesConfig, setCookiesConfig] = useState({
    essential: true,
    analytics: true
  });
  const [cookies, setCookies, removeCookies] = useCookies(["cookiesConsent", "_ga", "_ga_H9JMTM4T7J"]);

  const handleAccept = () => {
    setCookies("cookiesConsent", { essential: true, analytics: true }, { path: "/" });
    if (!cookiesConfig.analytics) {
      setCookies("cookiesConsent", { ...cookies.cookiesConsent, analytics: false }, { path: "/" });
      removeCookies("_ga", { path: "/" });
      removeCookies("_ga_H9JMTM4T7J", { path: "/" });
    }
    window.location.reload();
  };

  const handleDecline = () => {
    setCookies("cookiesConsent", { essential: true, analytics: false }, { path: "/" });
    removeCookies("_ga", { path: "/" });
    removeCookies("_ga_H9JMTM4T7J", { path: "/" });
  };

  return (
    <div className={styles.container}>
      <h3>We value your privacy</h3>
      <p className={styles.text}>
        This website use cookies to enhance your experience. By clicking "Accept", you consent to the use cookies. For
        more information, please read our{" "}
        <Link to="/cookies-policy" target="_blank" rel="noopener noreferrer">
          Cookies Policy
        </Link>
        .
      </p>
      <div className={styles.cookiesContainer}>
        <div className={styles.cookie}>
          <Switch checked={true} disabled />
          <label>Essential</label>
        </div>
        <div className={styles.cookie}>
          <Switch
            checked={cookiesConfig.analytics}
            onChange={(checked: boolean) => setCookiesConfig({ ...cookiesConfig, analytics: checked })}
          />
          <label>Analytics</label>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={handleDecline} className={styles.buttonDecline}>
          Decline
        </button>
        <button onClick={handleAccept} className={styles.buttonAccept}>
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookiesConsent;
