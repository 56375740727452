import { stylesheet } from "typestyle";

const style = stylesheet({
  container: {
    background: "#FFF",
    overflow: "hidden !important"
  },
  carrouselWrapper: {
    position: "absolute",
    width: "40%",
    zIndex: 99,
    top: "50%" /* Center vertically */,
    left: "70%" /* Center horizontally */,
    transform: "translate(-50%, -50%)"
  },
  image: {
    width: "70%",
    top: 0,
    height: "100%",
    backgroundSize: "contain",
    position: "relative",
    borderRadius: "0px 16px 16px 0px"
  },
  imageWrap: {
    width: "100%",

    $nest: {
      "@media screen and (max-width: 800px)": {
        width: "100%"
      }
    }
  },

  imageContainer: {
    borderRadius: "0px 16px 16px 0px",
    height: "88.5vh"
  },
  wrapper: {
    background: "white",
    padding:
      "0 calc(20px + 100 * ((100vw - 320px) / 1400)) calc(10px + 40 * ((100vw - 320px) / 1400)) calc(20px + 100 * ((100vw - 320px) / 1400))"
  },
  footer: {
    width: "100%",
    background: "#F6F9FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding:
      "calc(20px + 100 * ((100vw - 320px) / 1400)) calc(10px + 40 * ((100vw - 320px) / 1400)) calc(20px + 100 * ((100vw - 320px) / 1400))"
  },
  footerContent: {
    width: "65%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  subtitle: {
    color: "#1D264E",
    lineHeight: "1.5em",
    paddingBottom: "0.5em",
    fontWeight: 600,
    fontSize: "38px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "32px"
      }
    }
  },
  footerText: {
    textAlign: "center",
    color: "#474E70",
    lineHeight: "1.5em",
    paddingTop: "clamp(1em, 10vw, 1em)",
    fontWeight: 400,
    fontSize: "18px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "16px"
      }
    }
  },

  buttonText: {
    textAlign: "center",
    color: "#616783",
    margin: '0 1em',
    cursor: 'pointer',
    lineHeight: "20px",
    fontWeight: 500,
    fontSize: "16px",
    padding: "1.3em 1.8em",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "14px"
      }
    }
  },

  download: {
    fontSize: "16px",
    padding: ".8em 1.8em",
    borderRadius: "48px",
    cursor: "pointer",
    marginTop: "2em",
    display: "flex",
    justifyContent: "center",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        marginLeft: "0em",
        marginTop: "2em"
      }
    }
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

export default style;
