import { stylesheet } from "typestyle";

const style = stylesheet({
  container: {
    background: "#FFF",
    overflow: "hidden !important"
  },
  carrouselWrapper: {
    position: "absolute",
    width: "40%",
    zIndex: 99,
    top: "50%" /* Center vertically */,
    left: "70%" /* Center horizontally */,
    transform: "translate(-50%, -50%)"
  },
  image: {
    width: "100%",
    top: 0,
    height: "100%",
    backgroundSize: "contain",
    position: "relative",
    borderRadius: "0px 16px 16px 0px"
  },
  imageWrap: {
    width: "100%",

    $nest: {
      "@media screen and (max-width: 800px)": {
        width: "100%"
      }
    }
  },

  imageContainer: {
    borderRadius: "0px 16px 16px 0px",
    height: "88.5vh"
  },
  wrapper: {
    background: "white",
    padding:
      "0 calc(20px + 100 * ((100vw - 320px) / 1400)) calc(10px + 40 * ((100vw - 320px) / 1400)) calc(20px + 100 * ((100vw - 320px) / 1400))"
  },
  footerNoBackground: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding:
      "60px calc(20px + 100 * ((100vw - 320px) / 1400)) 40px"
  },
  footer: {
    width: "100%",
    background: "#F6F9FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding:
      "calc(20px + 100 * ((100vw - 320px) / 1400)) calc(10px + 40 * ((100vw - 320px) / 1400)) calc(20px + 100 * ((100vw - 320px) / 1400))"
  },
  footerContent: {
    width: "65%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  integrationContent: {
    width: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },

  subtitle: {
    color: "#1D264E",
    lineHeight: "1.5em",
    paddingBottom: "0.5em",
    fontWeight: 600,
    fontSize: "38px",
    textAlign: 'center',
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "32px"
      }
    }
  },
  footerText: {
    textAlign: "center",
    color: "#474E70",
    lineHeight: "1.5em",
    paddingTop: "clamp(1em, 10vw, 1em)",
    fontWeight: 400,
    fontSize: "18px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "16px"
      }
    }
  },
  download: {
    fontSize: "16px",
    padding: ".8em 1.8em",
    borderRadius: "48px",
    cursor: "pointer",
    marginTop: "2em",
    display: "flex",
    justifyContent: "center",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        marginLeft: "0em",
        marginTop: "2em"
      }
    }
  },
  card: {
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #E6E9ED",
    padding: "19px",
    borderRadius: "8px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        width: "100%"
      }
    }
  },
  logo: {
    width: "110px",
    maxWidth: "100px"
  },

  intContainer: {
    display: "grid",
    paddingTop: "20px",
    width: "100%",
    gap: "1em",
    "-ms-grid-columns": "1fr 1fr 1fr",
    gridTemplateColumns: "1fr 1fr 1fr",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    $nest: {
      "@media screen and (max-width: 800px)": {
        "-ms-grid-columns": "1fr",
        gridTemplateColumns: "1fr"
      }
    }
  },
  text: {
    color: "#1D264E",
    padding: "70px 0 0 0",
    fontWeight: 600,
    textAlign: "center",
    fontSize: "38px",
    lineHeight: "1.5em",
    paddingBottom: "1em",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "28px"
      }
    }
  }
});

export default style;
