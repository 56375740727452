/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import styles from "./styles.module.css";
import { LanguageCode, Legal } from "../../utils/types";
import { getLegalTerms } from "../../services/squidex";
import ReactMarkdown from "react-markdown";
import Footer from "../../components/Footer";
import { LanguageStore, useLanguageStore } from "../../store/languageStore";

const TermsOfService: React.FC = () => {
  const history = useHistory();

  const language: LanguageCode = useLanguageStore((state: LanguageStore) => state.language);
  const [legal, setLegal] = useState<Legal>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLegalTerms();
        setLegal(data as Legal);
        document.title = `RAVENT • ${data.title[language]}`;
        document.getElementById("preview1")?.setAttribute("content", data.text[language]);
        document.getElementById("previewOg")?.setAttribute("content", data.text[language]);
        document.getElementById("previewTwitter")?.setAttribute("content", data.text[language]);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [language]);

  return (
    <div>
      <div className={styles.container}>
        <Header
          openMobile={() => history.push("/menu")}
          onClick={() => history.push("/contact")}
          onClickLogo={() => history.push("/")}
        />
        <div className={styles.wrapper}>
          <h1 className={styles.title}>{legal?.title[language]}</h1>
          {legal?.text[language] ? <ReactMarkdown>{legal.text[language]}</ReactMarkdown> : null}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;
