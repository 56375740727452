import React from "react";
import { classes } from "typestyle";
import styles from "./styles";

export interface Props {
  containerClassname?: string;
  theme: "white" | "blue" | "red";
  padding?: string;
  onClick?: () => void;
  border?: boolean;
  boxShadow?: string;
}

export const Card: React.FC<Props> = ({ children, containerClassname, theme, padding, onClick, border, boxShadow }) => {
  return (
    <div
      onClick={onClick}
      className={classes(styles.cardStyle, containerClassname, {
        [styles.buttonBlue]: theme === "blue",
        [styles.buttonWhite]: theme === "white",
        [styles.buttonRed]: theme === "red",
        [styles.border]: border,
        [styles.cursor]: onClick
      })}
      style={{ padding, boxShadow }}
    >
      {children}
    </div>
  );
};
