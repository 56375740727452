import React, { useEffect, useState } from "react";
import styles from "./styles";
import logo from "../../assets/icons/logo-blue.svg";
import { useHistory } from "react-router-dom";
import menu from "../../assets/icons/menu.svg";
import { getLanguages, getNavigationMenu, getUpperButtons } from "../../services/squidex";
import { LanguageCode, LanguagesResponse, Menu, UpperButton } from "../../utils/types";
import LanguageSelector from "./LanguageSelector";
import { LanguageStore, useLanguageStore } from "../../store/languageStore";
// import { hardData } from "../../utils/nav";
// import Navigation2 from "./Navigation2";
import Navigation from "./Navigation";

interface Props {
  onClickLogo?: () => void;
  openMobile: () => void;
  onClick: () => void;
  rotate?: boolean;
}

// interface LinkItem {
//   title: string;
//   link: string;
// }

// interface SectionItem {
//   section: string;
//   items: LinkItem[];
// }

// interface Section {
//   title: string;
//   links: SectionItem[];
// }

const Home: React.FC<Props> = ({ onClickLogo, openMobile, onClick, rotate }) => {
  const [focus, setFocus] = React.useState("");
  const history = useHistory();

  const language: LanguageCode = useLanguageStore((state: LanguageStore) => state.language);
  const [navigationMenu, setMenu] = useState<Menu[]>([]);
  // const [navigationMenu, setMenu] = useState<Section[]>();
  const [buttons, setButtons] = useState<UpperButton[]>();
  const [languages, setLanguages] = useState<LanguagesResponse>();

  useEffect(() => {
    async function fetchData() {
      const navData = await getNavigationMenu();
      const buttonsData = await getUpperButtons();
      const languagesData = await getLanguages();

      setMenu(navData[language]);
      // setMenu(hardData);
      setButtons(buttonsData[language]);
      setLanguages(languagesData);
    }

    fetchData();
  }, [language]);

  return (
    <div className={styles.header} onMouseLeave={() => setTimeout(() => setFocus(""), 150)}>
      <img src={logo} alt="Ravent logo" className={styles.logo} onClick={() => onClickLogo?.()} />

      <div className={`${rotate ? styles.headerRotate : styles.headerMobile}`}>
        <img
          src={menu}
          alt="Menu collapsable icon"
          className={`${!rotate && styles.iconMenu}`}
          onClick={() => openMobile?.()}
        />
      </div>

      {!rotate && (
        <div className={styles.wrapper}>
          {/* <Navigation2 navigationMenu={navigationMenu as Section[]} focus={focus} setFocus={setFocus} /> */}
          <Navigation navigationMenu={navigationMenu as Menu[]} focus={focus} setFocus={setFocus} />

          <div className={styles.buttons}>
            <a className={styles.loginText} href={buttons?.[0].link} target="_blank" rel="noopener noreferrer">
              <div className={styles.loginButton}>{buttons?.[0].label}</div>
            </a>
            <button className={styles.buttonContact} onClick={() => history.push(`${buttons?.[1].link}`)}>
              {buttons?.[1].label}
            </button>

            {languages && <LanguageSelector languages={languages} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
