import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router";
import { getCarrouselData, getHome } from "../../services/squidex";
import { CarrouselResponse, HomeResponse, LanguageCode } from "../../utils/types";
import { Carousel } from "antd";
import { InfoCarrousel } from "../../components/InfoCarrouselCard";
import background from "../../assets/images/background-blue.png";
import background2 from "../../assets/images/background-boxoffice.jpg";

import styles from "./styles";
import Footer from "../../components/Footer";
import { Button } from "../../components/Button";
import { LanguageStore, useLanguageStore } from "../../store/languageStore";
import { HomeCarrouselContentSkeleton } from "../ContentLoader";

interface HomeCarrouselProps {
  rotate: boolean;
}

const HomeCarrousel: React.FC<HomeCarrouselProps> = ({ rotate }) => {
  const refFrontPage = useRef<HTMLDivElement>(null);
  const refContact = useRef<HTMLDivElement>(null);

  const language: LanguageCode = useLanguageStore((state: LanguageStore) => state.language);
  const [carrousel, setCarrousel] = useState<CarrouselResponse>();
  const [home, setHome] = useState<HomeResponse>();
  const history = useHistory();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const carrouselData = await getCarrouselData();
      const homeData = await getHome();
      carrouselData.items = carrouselData?.items.sort(() => Math.random() - 0.5);

      setCarrousel(carrouselData as CarrouselResponse);
      setHome(homeData);
      setIsReady(true);
    }

    fetchData();
  }, [language]);

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current != null) {
      history.push("/");
      ref.current.scrollIntoView();
    }
  };

  useEffect(() => {
    document.title = "RAVENT • The service fulfillment platform";
  });

  return (
    <div ref={refFrontPage}>
      <Header
        openMobile={() => history.push("/menu")}
        onClick={() => executeScroll(refContact)}
        onClickLogo={() => history.push("/")}
        rotate={rotate}
      />
      <div
        className={`${rotate ? styles.containerRotate : styles.container}`}
        style={{
          backgroundImage: `url(${background})`,
          width: "100%"
        }}
      >
        {isReady ? (
          <>
            <div className={styles.content}>
              <div className={styles.text}>{home?.content[language].title}</div>
              <div className={styles.textSmall}>{home?.content[language].text}</div>
              <div className={styles.buttonContainer}>
                <Button
                  containerClassname={styles.button}
                  onClick={() => history.push("/download")}
                  label={"Download App"}
                  height="50px"
                  width="200px"
                />
              </div>
            </div>
            <div className={styles.carrouselContainer}>
              <Carousel dotPosition={"right"} effect="fade" autoplaySpeed={3000} autoplay>
                {carrousel
                  ? carrousel?.items.map((item, index) => (
                      <div key={item.id + index}>
                        <div className={styles.carrouselWrapper}>
                          <InfoCarrousel
                            onClickLink={() =>
                              history.push(
                                `/use-cases/${item.data.slurp[language]}/${language}/${item.data.link[language]}`
                              )
                            }
                            title={item.data.title[language]}
                            info={item.data.text[language]}
                          />
                        </div>
                        <div className={styles.imageContainer}>
                          {!item.data.image ? null : (
                            <img
                              className={styles.image}
                              src={`https://cms.ravent.com/api/assets/ravent-landing-page/${item.data.image}`}
                              alt={item.data.title[language]}
                            />
                          )}
                        </div>
                      </div>
                    ))
                  : null}
              </Carousel>
            </div>
          </>
        ) : (
          <HomeCarrouselContentSkeleton />
        )}
      </div>

      <div className={styles.part1}>
        <div className={styles.img1}>
          <img
            src={`https://cms.ravent.com/api/assets/ravent-landing-page/${home?.contentImage1[language].image[0].file[0]}`}
            alt="pic1"
          />
        </div>
        <p>{home?.contentImage1[language].text}</p>
      </div>

      <h3 className={styles.subtitleMob}>{home?.contentImage2[language].text}</h3>
      <div
        style={{
          backgroundImage: `url(${background2})`,
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          paddingBlock: "4rem"
        }}
      >
        <div className={styles.part2}>
          <h3>{home?.contentImage2[language].text}</h3>
          <div className={styles.img2}>
            <img
              src={`https://cms.ravent.com/api/assets/ravent-landing-page/${home?.contentImage2[language].image[0].file[0]}`}
              alt="pic2"
            />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={styles.subtitle}>{home?.homeFooter[language].title}</div>
          <div className={styles.footerText}>{home?.homeFooter[language].text}</div>
        </div>
        <Button
          onClick={() => history.push(home?.homeFooter[language].button[0].link || "")}
          height="50px"
          buttonClassname={styles.download}
          label={home?.homeFooter[language].button[0].label || ""}
        />
      </div>

      <div ref={refContact}>
        <Footer />
      </div>
    </div>
  );
};

HomeCarrousel.defaultProps = {
  rotate: false
};

export default HomeCarrousel;
