import React, { useState } from "react";
import { LanguageCode, LanguagesResponse } from "../../utils/types";
import styles from "./styles";
import icon from "../../assets/icons/languageIcon.svg";
import { LanguageStore, useLanguageStore } from "../../store/languageStore";

interface Props {
  languages: LanguagesResponse;
}

const LanguageSelector: React.FC<Props> = ({ languages }) => {
  const language: LanguageCode = useLanguageStore((state: LanguageStore) => state.language);
  const setLanguage = useLanguageStore((state: LanguageStore) => state.setLanguage);
  const [isOpen, setIsOpen] = useState(false);

  const handlelanguageModal = () => setIsOpen(!isOpen);

  return (
    <div className={styles.language} onClick={handlelanguageModal}>
      <img src={icon} alt="Globe Icon" />
      <p>{language}</p>

      {isOpen && (
        <div className={styles.languageModal}>
          {languages[language]
            .sort((a, b) => (a.code === "en" ? -1 : b.code === "en" ? 1 : 0))
            .map((lan) => (
              <p
                key={lan.code}
                onClick={() => setLanguage(lan.code)}
                className={language === lan.code ? `${styles.languageSelected}` : ""}
              >
                {lan.name}
              </p>
            ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
