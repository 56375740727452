import { stylesheet } from "typestyle";

const style = stylesheet({
  container: {
    overflow: "hidden !important",
    "-ms-grid-columns": "1fr",

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    gridTemplateColumns: "1fr 1fr",
    display: "grid",
    $nest: {
      "@media screen and (max-width: 800px)": {
        gridTemplateColumns: "1fr"
      }
    }
  },
  containerRotate: {
    overflow: "hidden !important",
    "-ms-grid-columns": "1fr",

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    gridTemplateColumns: "1fr 1fr",
    display: "grid"
  },
  button: {
    width: "100%",
    minWidth: "150px",
    maxWidth: "200px"
  },

  carrouselWrapper: {
    position: "absolute",
    bottom: "1%",
    left: "1%",
    width: "50%",
    zIndex: 99999,
    $nest: {
      "@media screen and (max-width: 800px)": {
        // transform: "translate(-50%, -50%)",
        right: "5%",
        left: "5%",
        width: "90%"
      },
      ".ant-carousel .slick-dots li button": {
        backgroundColor: "red"
      },

      /* Change the color of the active dot */
      ".ant-carousel .slick-dots li.slick-active button": {
        backgroundColor: "purple"
      }
    }
  },
  textSmall: {
    textAlign: "justify",
    color: "rgba(29, 38, 78, 1)",
    lineHeight: "1.5em",
    paddingTop: "clamp(2em, 10vw, 2em)",
    fontWeight: 400,

    fontSize: "18px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "18px"
      }
    }
  },
  store: {
    maxWidth: "152px",
    marginRight: "1em"
  },

  buttonContainer: {
    margin: "clamp(1em, 20vw, 1em) 0",
    display: "flex",
    justifyContent: "flex-start"
  },

  text: {
    color: "rgba(29, 38, 78, 1)",
    fontWeight: 600,
    fontSize: "48px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "30px"
      }
    }
  },

  content: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    fontSize: "calc(16px + 22 * ((100vw - 320px) / 1400))",
    padding: "calc(20px + 100 * ((100vw - 320px) / 1400)) calc(10px + 100 * ((100vw - 320px) / 1400))",
    $nest: {
      "@media screen and (max-width: 800px)": {
        padding: "calc(20px + 100 * ((100vw - 320px) / 1400)) calc(10px + 100 * ((100vw - 320px) / 1400))"
      }
    }
  },
  image: {
    height: "80%",
    borderRadius: "16px 16px 16px 16px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        borderRadius: "0px"
      }
    }
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "85vh",
    $nest: {
      "@media screen and (max-width: 800px)": {
        display: "block"
      }
    }
  },

  carrouselContainer: {
    $nest: {
      "@media screen and (max-width: 800px)": {
        right: "5%",
        left: "5%"
      },
      ".ant-carousel .slick-dots li button": {
        backgroundColor: "#1D264E"
      },
      ".ant-carousel .slick-dots li.slick-active button": {
        backgroundColor: "#0163E7"
      }
    }
  },

  imgVideo: {
    maxWidth: "430px",
    marginLeft: "2em",
    $nest: {
      "@media screen and (max-width: 800px)": {}
    }
  },

  part1: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    marginBlock: "4rem",
    marginInline: "auto",
    width: "87.5%",
    alignItems: "center",

    $nest: {
      p: {
        width: "90%",
        minWidth: "200px",
        maxWidth: "450px",
        fontSize: "18px",
        lineHeight: "1.5",
        textAlign: "justify",
        color: "#1D264E",
        marginInline: "auto",

        "@media screen and (max-width: 800px)": {
          width: "95%",
          marginTop: "2rem",
          textAlign: "start"
        }
      },
      "@media screen and (max-width: 800px)": {
        gridTemplateColumns: "1fr"
      }
    }
  },

  img1: {
    overflow: "hidden",
    width: "100%",
    maxWidth: "450px",
    minWidth: "200px",

    $nest: {
      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "center"
      }
    }
  },

  subtitleMob: {
    display: "none",

    $nest: {
      "@media screen and (max-width: 800px)": {
        display: "block",
        fontSize: "36px",
        marginBottom: "2rem",
        width: "90%",
        marginInline: "auto",
        color: "#1D264E"
      }
    }
  },

  part2: {
    display: "flex",
    marginInline: "auto",
    width: "87.5%",
    justifyContent: "space-between",
    alignItems: "center",

    $nest: {
      "@media screen and (max-width: 800px)": {
        flexDirection: "column",
        width: "95%"
      },

      h3: {
        fontSize: "48px",
        color: "#1D264E",
        "@media screen and (max-width: 800px)": {
          display: "none"
        }
      }
    }
  },

  img2: {
    overflow: "hidden",
    width: "100%",
    maxWidth: "900px",
    minWidth: "200px",
    marginInline: "auto",

    $nest: {
      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "center"
      }
    }
  },
  footer: {
    width: "100%",
    background: "#F6F9FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding:
      "calc(20px + 100 * ((100vw - 320px) / 1400)) calc(10px + 40 * ((100vw - 320px) / 1400)) calc(20px + 100 * ((100vw - 320px) / 1400))",
    paddingBlock: "6rem"
  },
  footerContent: {
    width: "65%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    $nest: {
      "@media screen and (max-width: 800px)": {
        width: "90%"
      }
    }
  },
  subtitle: {
    color: "#1D264E",
    textAlign: "center",
    lineHeight: "1.25em",
    paddingBottom: "0.5em",
    fontWeight: 600,
    fontSize: "38px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "32px"
      }
    }
  },
  footerText: {
    textAlign: "center",
    color: "#474E70",
    lineHeight: "1.5em",
    paddingTop: "clamp(1em, 10vw, 1em)",
    fontWeight: 400,
    fontSize: "18px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "16px"
      }
    }
  },
  download: {
    fontSize: "16px",
    padding: ".8em 1.8em",
    borderRadius: "48px",
    cursor: "pointer",
    marginTop: "2em",
    display: "flex",
    justifyContent: "center",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        marginLeft: "0em",
        marginTop: "2em"
      }
    }
  }
});

export default style;
