import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { useHistory } from "react-router";
import styles from "./styles.module.css";
import Footer from "../../components/Footer";
import { LanguageCode, TryAndBuyContent } from "../../utils/types";
import { getTryAndBuyContent } from "../../services/squidex";
import { LanguageStore, useLanguageStore } from "../../store/languageStore";
import { InlineWidget } from "react-calendly";


const TryAndBuy: React.FC = () => {
  const refFrontPage = useRef<HTMLDivElement>(null);
  const refContact = useRef<HTMLDivElement>(null);
  const [form, setForm] = useState<TryAndBuyContent>();

  const language: LanguageCode = useLanguageStore((state: LanguageStore) => state.language);
  const history = useHistory();

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current != null) {
      history.push("/");
      ref.current.scrollIntoView();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getTryAndBuyContent();
        setForm(res);
        document.title = `RAVENT • ${res.title[language]}`;
        document.getElementById("preview1")?.setAttribute("content", res.text[language]);
        document.getElementById("previewOg")?.setAttribute("content", res.text[language]);
        document.getElementById("previewTwitter")?.setAttribute("content", res.text[language]);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [language]);

  return (
    <div ref={refFrontPage}>
      <Header
        openMobile={() => history.push("/menu")}
        onClick={() => executeScroll(refContact)}
        onClickLogo={() => history.push("/")}
      />
      <div className={styles.container}>
        <div className={styles.subtitle}>{form?.title[language]}</div>
        <div className={styles.footerText}>{form?.text[language]}</div>
        <div className={styles.calendlyContainer}>
          <InlineWidget
            url="https://calendly.com/ravent-explore/60mins"
            styles={{
              width: "100%",
              height: "100%"
            }}
          />
        </div>

      </div>

      <div ref={refContact}>
        <Footer />
      </div>
    </div>
  );
};

export default TryAndBuy;
