import React from "react";
import ContentLoader from "react-content-loader";

export const HomeCarrouselContentSkeleton = () => {
  return (
    <React.Fragment>
      <ContentLoader
        speed={2}
        width={1815}
        height={850}
        viewBox="0 0 1815 850"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <path d="M 666.5 167.5 h -549 c -6.6 0 -12 -5.4 -12 -12 v -5 c 0 -6.6 5.4 -12 12 -12 h 549 c 6.6 0 12 5.4 12 12 v 5 c 0 6.6 -5.4 12 -12 12 z M 784.5 243.5 h -666 c -4.95 0 -9 -4.05 -9 -9 s 4.05 -9 9 -9 h 666 c 4.95 0 9 4.05 9 9 s -4.05 9 -9 9 z M 784.5 273.5 h -666 c -4.95 0 -9 -4.05 -9 -9 s 4.05 -9 9 -9 h 666 c 4.95 0 9 4.05 9 9 s -4.05 9 -9 9 z M 784.5 303.5 h -666 c -4.95 0 -9 -4.05 -9 -9 s 4.05 -9 9 -9 h 666 c 4.95 0 9 4.05 9 9 s -4.05 9 -9 9 z M 784.5 333.5 h -666 c -4.95 0 -9 -4.05 -9 -9 s 4.05 -9 9 -9 h 666 c 4.95 0 9 4.05 9 9 s -4.05 9 -9 9 z M 439.5 362.5 h -321 c -4.95 0 -9 -4.05 -9 -9 s 4.05 -9 9 -9 h 321 c 4.95 0 9 4.05 9 9 s -4.05 9 -9 9 z M 256.32 456.5 H 134.68 c -12.2 0 -22.18 -9.98 -22.18 -22.18 v -4.64 c 0 -12.2 9.98 -22.18 22.18 -22.18 h 121.64 c 12.2 0 22.18 9.98 22.18 22.18 v 4.64 c 0 12.2 -9.98 22.18 -22.18 22.18 z M 1679.5 83.5 h -639 c -6.6 0 -12 5.4 -12 12 v 451 h 331 c 6.6 0 12 5.4 12 12 v 161 h 308 c 6.6 0 12 -5.4 12 -12 v -612 c 0 -6.6 -5.4 -12 -12 -12 z" />
        <path d="M 1129 600.5 H 945 c -4.67 0 -8.5 -3.83 -8.5 -8.5 v -5 c 0 -4.67 3.83 -8.5 8.5 -8.5 h 184 c 4.68 0 8.5 3.83 8.5 8.5 v 5 c 0 4.67 -3.82 8.5 -8.5 8.5 z M 1292.5 646.5 h -349 c -3.85 0 -7 -3.15 -7 -7 v -2 c 0 -3.85 3.15 -7 7 -7 h 349 c 3.85 0 7 3.15 7 7 v 2 c 0 3.85 -3.15 7 -7 7 z M 1332.5 669.54 H 943.96 c -3.85 0 -7 -3.15 -7 -7 v -2 c 0 -3.85 3.15 -7 7 -7 h 388.54 c 3.85 0 7 3.15 7 7 v 2 c 0 3.85 -3.15 7 -7 7 z M 1292.96 694.54 h -349 c -3.85 0 -7 -3.15 -7 -7 v -2 c 0 -3.85 3.15 -7 7 -7 h 349 c 3.85 0 7 3.15 7 7 v 2 c 0 3.85 -3.15 7 -7 7 z M 1232.5 718.54 H 943.96 c -3.85 0 -7 -3.15 -7 -7 v -2 c 0 -3.85 3.15 -7 7 -7 h 288.54 c 3.85 0 7 3.15 7 7 v 2 c 0 3.85 -3.15 7 -7 7 z M 1067.5 769.5 h -113 c -9.9 0 -18 -8.1 -18 -18 s 8.1 -18 18 -18 h 113 c 9.9 0 18 8.1 18 18 s -8.1 18 -18 18 z" />
      </ContentLoader>
    </React.Fragment>
  );
};

export const AboutUsContenSkeleton = () => {
  return (
    <React.Fragment>
      <ContentLoader
        speed={2}
        width={1821.333}
        height={714.667}
        viewBox="0 0 1821.333 714.667"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <path d="M 577 128.5 V 154 h 217 v -51 H 577 v 25.5 z M 306 199.5 V 221 h 747 v -43 H 306 v 21.5 z M 312 249 v 19 h 738 v -38 H 312 v 19 z M 277 296 v 21 h 807 v -42 H 277 v 21 z M 280 342.5 V 363 h 802 v -41 H 280 v 20.5 z M 270 389 v 19 h 825 v -38 H 270 v 19 z M 274 441.5 V 464 h 817 v -45 H 274 v 22.5 z M 507 487 v 18 h 352 v -36 H 507 v 18 z" />
      </ContentLoader>
    </React.Fragment>
  );
};

export const ContactUsContenSkeleton = () => {
  return (
    <React.Fragment>
      <ContentLoader
        speed={2}
        width={1821.333}
        height={714.667}
        viewBox="0 0 1821.333 714.667"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <path d="M 590 80.5 V 99 h 199 V 62 H 590 v 18.5 z M 203 137 v 16 h 959 v -32 H 203 v 16 z M 593 270 v 25 h 208 v -50 H 593 v 25 z M 804 270 v 25 h 210 v -50 H 804 v 25 z M 356 271.5 V 286 h 111 v -29 H 356 v 14.5 z M 596 308.5 V 319 h 72 v -21 h -72 v 10.5 z M 804 310 v 11 h 70 v -22 h -70 v 11 z M 591 358 v 23 h 429 v -46 H 591 v 23 z M 354 360 v 13 h 108 v -26 H 354 v 13 z M 714 397.5 V 408 h 80 v -21 h -80 v 10.5 z M 830 399 v 12 h 102 v -24 H 830 v 12 z M 595 400.5 V 411 h 87 v -21 h -87 v 10.5 z M 590 449 v 24 h 434 v -48 H 590 v 24 z M 354 450 v 15 h 112 v -30 H 354 v 15 z M 592 490 v 13 h 145 v -26 H 592 v 13 z M 590 532 v 18 h 450 v -36 H 590 v 18 z M 351 539 v 11 h 179 v -22 H 351 v 11 z M 1365.3 543 c 0 4.1 0.2 5.8 0.4 3.8 c 0.2 -2.1 0.2 -5.5 0 -7.6 c -0.2 -2 -0.4 -0.3 -0.4 3.8 z" />
      </ContentLoader>
    </React.Fragment>
  );
};
