import { stylesheet } from "typestyle";

const styles = stylesheet({
  image: {
    objectFit: "cover",
    width: "100%",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    height: "20em"
  },
  background: {
    backgroundColor: "#F6F9FF",
    minHeight: "100vH"
  },
  textContainer: {
    position: "absolute",
    top: "12%",
    left: "25%",
    width: "70%"
  },
  borderRadius: {
    borderRadius: 30,
    padding: "1em 0 0 0"
  },

  infoContainer: {
    paddingTop: "4em",
    marginBottom: "6em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  infoContainerTitle: {
    marginBottom: "1rem",
    fontSize: "48px",
    color: "#1D264E",
    textAlign: "center"
  },
  infoContainerText: {
    fontSize: "18px",
    color: "#474E70",
    textAlign: "center"
  },
  cardContainer: {
    margin: "0 auto",
    backgroundColor: "#FFFFFF",
    boxShadow: "-3px 3px 20px 0 #DDDDDD",
    display: "flex",
    alignItems: "center",
    minWidth: "300px",
    width: "100%",
    maxWidth: "500px",
    minHeight: "100px",
    height: "auto",
    padding: "2em",
    gap: "2em"
  },
  cardContainerIcon: {
    width: "40px",
    height: "40px"
  },
  cardContainerText: {
    color: "#474E70",
    fontSize: "18px",
    lineHeight: "1.5"
  },
  download: {
    fontSize: "1.2em",
    padding: ".8em 1.8em",
    borderRadius: "48px",
    cursor: "pointer",
    marginLeft: "1em",
    display: "flex",
    justifyContent: "center",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        marginLeft: "0em",
        marginTop: "2em"
      }
    }
  },
  text: {
    fontSize: "2em"
  },
  containerSelect: {
    padding: "1em 1.5em"
  },
  orange: {
    background: "#F38D38",
    padding: "1.5em 1em",
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  bottomContainer: {}
});

export default styles;
