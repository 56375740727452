import React from "react";
import { GridContainer } from "../GridContainer/GridContainer";
import Mobile from "./Mobile";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import ReactMarkdown from "react-markdown";

import styles from "./styles";

interface Props {
  text: string;
  imageStyle: string;
  image: string;
  color: string;
  title: string;
  isVideo: boolean;
  alt: string;
}

const TextImage: React.FC<Props> = ({ alt, text, image, imageStyle, color, title, isVideo }) => {
  return (
    <div>
      <div className={styles.mobileContainer}>
        <Mobile alt={alt} isVideo={isVideo} color={color} image={image} imageStyle={imageStyle} subtitle={title} text={text} />
      </div>
      <div className={styles.container}>
        <div style={{ backgroundColor: color }} className={styles.content}>
          <GridContainer justifyContent="center" alignItems="center" gap="1em" columns="1fr 1fr">
            <div className={styles.textContent}>
              <FlexContainer justifyContent="space-between">
                <ReactMarkdown className={styles.subtitle}>{title}</ReactMarkdown>
              </FlexContainer>
              <ReactMarkdown className={styles.textSmall}>{text}</ReactMarkdown>
            </div>

            {isVideo ? (
              <video loop autoPlay className={styles.videoContainer}>
                <source src={image} type="video/mp4" className={styles.imageWrapVideo} />
              </video>
            ) : (
              <div className={styles.imageContainer}>
                <img src={image} alt={text} className={imageStyle} />
              </div>
            )}
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

export default TextImage;
