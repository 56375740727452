import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

interface Props {}

const RedirectContainer: React.FC<Props> = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/Android/i) !== null) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.ravent.concierge";
    }
    if (userAgent.match(/iPhone|iPad|iPod/i) !== null) {
      window.location.href = "https://apps.apple.com/app/ravent-concierge/id1575005586";
    }
    return;
  }, []);

  return <Redirect to={{ pathname: "/download" }} />;
};

export default RedirectContainer;
