import React, { useEffect, useState } from "react";
import { Route, Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import CustomerSolutions from "./containers/CustomerSolutions";
import Functionality from "./containers/Functionality";
import Privacy from "./containers/Privacy";
import TeamSolutions from "./containers/TeamSolutions";
import HeaderMobile from "./components/Header/HeaderMobile";
import HomeCarrousel from "./containers/HomeCarrousel";
import ContentContainer from "./containers/ContentContainer";
import TermsOfService from "./containers/TermsOfService";
import TryAndBuy from "./containers/TryAndBuy";
import AboutUs from "./containers/AboutUs";
import ThankYou from "./containers/ThankYou";
import Integrations from "./containers/Integrations";
import ContactUs from "./containers/ContactUs";
import Impress from "./containers/Impress";
import CookiesPolicy from "./containers/CookiesPolicy";
import { useCookies } from "react-cookie";
import CookiesConsent from "./components/CookieConsent";
import { LanguageStore, useLanguageStore } from "./store/languageStore";
import { LanguageCode } from "./utils/types";
import SitemapGenerator from "./containers/SiteMapGenerator";
import EraseData from "./containers/EraseData";
import Maintenance from "./containers/Maintenance ";
import Download from "./containers/Download";
import RedirectContainer from "./containers/RedirectContainer";

function App(): JSX.Element {
  const [cookies, , removeCookies] = useCookies(["cookiesConsent", "_ga", "_ga_H9JMTM4T7J"]);
  const language: LanguageCode = useLanguageStore((state: LanguageStore) => state.language);
  const setLanguage = useLanguageStore((state: LanguageStore) => state.setLanguage);
  const [rotate, setRotate] = useState(false);

  const regexLan = /\/(es|en|de)\b/;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setRotate(params.get("rotate") === "true");
  }, []);

  useEffect(() => {
    if (!cookies.cookiesConsent?.analytics) {
      removeCookies("_ga", { path: "/" });
      removeCookies("_ga_H9JMTM4T7J", { path: "/" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.cookiesConsent?.essential]);

  useEffect(() => {
    let urlLan = pathname.match(regexLan)?.[1];
    if (!urlLan) urlLan = "en";
    if (urlLan !== language) {
      setLanguage(urlLan as LanguageCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!language) setLanguage("en");
    if (regexLan.test(pathname)) {
      const newPath = pathname.replace(regexLan, `/${language || "en"}`);
      history.replace(newPath);
    } else {
      if (pathname.length < 2) {
        const newPath = pathname.replace("/", "") + `/${language || "en"}`;
        history.replace(newPath);
      } else {
        const newPath = pathname + `/${language || "en"}`;
        history.replace(newPath);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, pathname]);

  return (
    <div className={`App ${rotate ? "rotate" : ""}`}>
      {!cookies.cookiesConsent && <CookiesConsent />}

      <Switch>
        <Route path="/functionality" component={Functionality} />
        <Route path="/solutions/team" component={TeamSolutions} />
        <Route path="/menu" component={HeaderMobile} />
        <Route path="/solutions/customer" component={CustomerSolutions} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/impress" component={Impress} />
        <Route path="/data-deletion-request" component={EraseData} />
        <Route path="/maintenance" component={Maintenance} />
        <Route path="/terms-and-conditions" component={TermsOfService} />
        <Route path="/cookies-policy" component={CookiesPolicy} />
        <Route path="/integrations" component={Integrations} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/download" component={Download} />
        <Route path="/download-app" component={RedirectContainer} />

        <Route path="/try-and-buy" component={TryAndBuy} />
        <Route path="/thankyou" component={ThankYou} />
        <Route path="/use-cases" component={ContentContainer} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/generate-sitemap" component={SitemapGenerator} />
        <Route path="/" render={(props) => <HomeCarrousel {...props} rotate={rotate} />} />
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
}

export default App;
