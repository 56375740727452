import { stylesheet } from "typestyle";

const style = stylesheet({
  container: {
    $nest: {
      "@media screen and (max-width: 800px)": {}
    }
  },
  title: {
    color: "#0163E7",
    fontWeight: 700,
    fontSize: "20px",
    paddingBottom: "0.2em",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "16px"
      }
    }
  },
  textSmall2: {
    color: "#1D264E",
    lineHeight: "24px",
    paddingTop: "clamp(1em, 5vw, 1em)",
    fontWeight: 400,
    fontSize: "16px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "14px",
        textAlign: "left"
      }
    }
  },
  icon: {
    width: "32px",
    marginRight: "1em"
  },
  readMore: {
    cursor: "pointer",
    lineHeight: "26px",
    fontWeight: 700,
    color: '#1D264E',
    fontSize: "18px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "14px",
        textAlign: "left"
      }
    }
  }
});

export default style;
