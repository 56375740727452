import React from "react";
import { GridContainer } from "../GridContainer/GridContainer";
import styles from "./styles";
import { FlexContainer } from "../FlexContainer/FlexContainer";

interface Props {
  text: string;
  subtitle: string;
  image: string;
  imageStyle: string;
  color: string;
  isVideo: boolean;
  alt: string;
}

const Mobile: React.FC<Props> = ({alt, text, subtitle, image, imageStyle, color, isVideo }) => {
  return (
    <div style={{ backgroundColor: color }} className={styles.content}>
      <GridContainer gap="1em" columns="1fr">
        <div className={styles.textContent}>
          <FlexContainer justifyContent="space-between">
            <div className={styles.subtitle}>{subtitle}</div>
          </FlexContainer>
          <div className={styles.textSmall}>{text}</div>
        </div>

        {isVideo ? (
          <video loop autoPlay className={styles.videoContainer}>
            <source src={image} type="video/mp4" className={styles.imageWrapVideo} />
          </video>
        ) : (
          <div className={styles.imageContainer}>
            <img src={image} alt={alt|| ''} className={imageStyle} />
          </div>
        )}
      </GridContainer>
    </div>
  );
};

export default Mobile;
