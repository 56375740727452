import { stylesheet } from "typestyle";

const style = stylesheet({
  container: {
    width: "100%",
    maxWidth: "60rem",
    margin: "0 auto",
    padding: "calc(10px + 70 * ((100vw - 320px) / 1400)) calc(20px + 50 * ((100vw - 320px) / 1400))",
    fontSize: "16px",
    lineHeight: "1.5",
    $nest: {
      "@media (max-width: 768px)": {
        textAlign: "center"
      }
    }
  },
  title: {
    fontSize: "2.5rem",
    color: "#1D264E",
    textAlign: "center",
    marginBottom: "1rem"
  },
  subtitle: {
    fontSize: "1.5rem",
    color: "#1D264E",
    marginTop: "1rem"
  },
  cookieTitle: {
    fontSize: "1.1rem",
    color: "#1D264E",
    marginTop: "1rem",
    fontWeight: "bold"
  },
  cookieText: {
    marginBottom: "0.5rem",
    textAlign: "start"
  }
});

export default style;
