import React, { useEffect, useState } from "react";
import { FlexContainer } from "../FlexContainer/FlexContainer";
import styles from "./styles";
import { useHistory } from "react-router-dom";
import logo from "../../assets/icons/logo-blue.svg";
import quit from "../../assets/icons/quit.svg";
import { LanguageCode, Menu } from "../../utils/types";
import { getNavigationMenu } from "../../services/squidex";
import { LanguageStore, useLanguageStore } from "../../store/languageStore";

interface Props {
  onClick: () => void;
  rotate?: boolean;
}

const HeaderMobile: React.FC<Props> = ({ onClick }) => {
  const history = useHistory();

  const language: LanguageCode = useLanguageStore((state: LanguageStore) => state.language);
  const [navigationMenu, setMenu] = useState<Menu[]>([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getNavigationMenu();
      setMenu(data[language]);
    }
    fetchData();
  }, [language]);

  return (
    <div className={styles.wrapperMobile}>
      <FlexContainer justifyContent="space-between">
        <img src={logo} alt="Ravent logo" className={styles.logo} onClick={() => history.push("/")} />
        <img src={quit} alt="Fallback action" className={styles.quitIcon} onClick={() => history.goBack()} />
      </FlexContainer>

      <FlexContainer padding="2em 0" alignItems="flex-start" justifyContent="flex-start" flexDirection="column">
        {navigationMenu.map((item, index) => {
          if (item.menuItems) {
            return (
              <>
                <div key={index} className={styles.solutions}>
                  {item.title}
                </div>
                <FlexContainer padding="0 0 0 3em" flexDirection="column">
                  {item.menuItems.map((e) => (
                    <div className={styles.option} key={e.itemTitle} onClick={() => history.push(e.itemLink)}>
                      {e.itemTitle}
                    </div>
                  ))}
                </FlexContainer>
              </>
            );
          } else
            return (
              <button key={index} className={styles.contact} onClick={() => history.push(item.link)}>
                {item.title}
              </button>
            );
        })}

        <button className={styles.buttonContact} onClick={() => history.push("/try-and-buy")}>
          Try and Buy
        </button>
        <a
          className={styles.loginButton}
          href="https://app.ravent.com/orders"
          target="_blank"
          rel="noopener noreferrer"
        >
          Login
        </a>
      </FlexContainer>
    </div>
  );
};

export default HeaderMobile;
