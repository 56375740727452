import { stylesheet } from "typestyle";

const style = stylesheet({
  container: {
    background: "#FFF",
    padding: "0 calc(10px + 40 * ((100vw - 320px) / 1400))",
    $nest: {
      "@media screen and (max-width: 800px)": {
        display: "none"
      }
    }
  },
  mobileContainer: {
    background: "#FFF",
    padding: "calc(10px + 100 * ((100vw - 320px) / 1400)) calc(20px + 200 * ((100vw - 320px) / 1400))",
    $nest: {
      "@media screen and (min-width: 800px)": {
        display: "none",
        padding: 0
      }
    }
  },

  captures: {
    position: "absolute",
    zIndex: 2,
    top: "5em",
    right: 0,
    maxWidth: "40vw",
    width: "100%",
    paddingTop: "2em"
  },
  content: {
    fontSize: "calc(16px + 22 * ((100vw - 320px) / 1400))"
  },
  text: {
    color: "rgba(29, 38, 78, 1)",
    fontWeight: 600,
    fontSize: "40px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "30px"
      }
    }
  },

  name: {
    color: "#0163E7",
    fontWeight: 600,
    fontSize: "20px",
    paddingBottom: "1em",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "12px"
      }
    }
  },

  title: {
    color: "#1D264E",
    fontWeight: 600,
    fontSize: "48px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "36px"
      }
    }
  },

  heading: {
    color: "#1D264E",
    fontWeight: 700,
    fontSize: "38px",
    paddingTop: "clamp(1em, 10vw, 1em)",
    paddingInline: "2em",

    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "32px"
      }
    }
  },

  textSmall: {
    textAlign: "justify",
    color: "#474E70",
    lineHeight: "1.5em",
    paddingTop: "clamp(1em, 10vw, 1em)",
    fontWeight: 400,
    fontSize: "18px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "18px"
      }
    }
  },

  imageWrapVideo: {
    height: "623px",
    maxHeight: "623px",
    position: "relative",
    top: 0,
    left: 0,

    $nest: {
      "@media screen and (max-width: 800px)": {
        height: "323px"
      }
    }
  },

  imageWrap: {
    width: "90%",
    position: "relative",
    top: 0,
    left: 0,

    $nest: {
      "@media screen and (max-width: 800px)": {}
    }
  },

  download: {
    fontSize: "16px",
    padding: "1em 2em",
    borderRadius: "48px",
    cursor: "pointer",
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    $nest: {
      "@media screen and (max-width: 1000px)": {
        marginLeft: "0em",
        marginTop: "1em"
      }
    }
  },

  image: {
    maxWidth: "100%",
    width: "100%",
    position: "relative",
    top: 0,
    left: 0,

    $nest: {
      "@media screen and (max-width: 800px)": {}
    }
  },

  imageContainer: {
    position: "relative",
    top: 0,
    left: 0
  },
  videoContainer: {
    height: "623px",
    position: "relative",
    top: 0,
    left: 0,
    $nest: {
      "@media screen and (max-width: 800px)": {
        height: "323px"
      }
    }
  },

  logo: {
    width: "8em",
    fontSize: "calc(14px + 4 * ((100vw - 320px) / 1200))"
  },
  orange: {
    color: "#ff781f"
  },
  button: {
    color: "#fff"
  },
  buttonContainer: {
    margin: "clamp(1em, 20vw, 1em) 0"
  }
});

export default style;
