import { stylesheet } from "typestyle";

const style = stylesheet({
  container: {
    width: "100%",
    background: "#1D264E",
    color: "#fff",
    $nest: {
      "@media screen and (max-width: 800px)": {
        paddingTop: "0"
      }
    }
  },
  part: {
    padding: "20px 0",
    $nest: {
      div: {
        paddingTop: "0"
      },
      "@media screen and (max-width: 800px)": {
        paddingTop: "0"
      }
    }
  },
  columns: {
    display: "grid",
    gap: "1em",
    padding: "clamp(1em, 10vw, 3em) 0 clamp(1em, 10vw, 2em) 0",
    "-ms-grid-columns": "1fr 1fr 1fr 0.5fr",
    gridTemplateColumns: "1fr 2fr 1fr",
    maxWidth: 1200,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    margin: "0 clamp(1em, 10vw, 5em)",
    minHeight: "10em",

    $nest: {
      "@media screen and (max-width: 800px)": {
        "-ms-grid-columns": "1fr",
        gridTemplateColumns: "1fr",
        margin: "25px",
        marginBlock: 0,
        padding: "2rem 0 0 0"
      }
    }
  },
  text: {
    paddingBottom: ".5em",
    color: "#ffffff99",
    fontSize: "18px",
    fontWeight: 300,
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "15px"
      },
      strong: {
        color: "#fff"
      }
    }
  },
  icon: {
    width: "32px"
  },
  iconLinkTree: {
    width: "33x"
  },
  footerText: {
    padding: "clamp(1em, 10vw, 3em) clamp(1em, 10vw, 5em) clamp(1em, 10vw, 2em) clamp(1em, 10vw, 5em)",
    width: "100%",
    background: "#1D264E",
    bottom: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "clamp(0.9em, 5vw, 0.9em)",
    color: "#fff",

    $nest: {
      "@media screen and (max-width: 800px)": {
        padding: "25px",
        paddingTop: "0"
      }
    }
  },
  divisor: {
    width: "100%",
    backgroundColor: "#ffffff80",
    height: "0.5px",
    marginBottom: "30px"
  },
  link: {
    padding: "1em",
    color: "#fff",
    textDecoration: "underline",
    cursor: "pointer"
  },
  linkTree: {
    padding: "1 0.5em",
    color: "#fff",
    textDecoration: "underline",
    cursor: "pointer"
  },
  logo: {
    width: "200px"
  },
  talk: {
    display: "flex"
  },
  talkText: {
    fontSize: "clamp(1.8em, 5vw, 3em)",
    color: "#fff"
  },
  talkEmail: {
    marginTop: ".5em",
    fontSize: "clamp(1em, 5vw, 1.35em)"
  },
  dirTitle: {
    color: "#ffffff99",
    fontSize: "18px",
    fontWeight: 300,
    padding: "0em 0 10px 0",
    textTransform: "uppercase",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "16px"
      }
    }
  },
  copy: {
    color: "#fff"
  },
  contact: {
    fontSize: "18px",
    fontWeight: 300,

    $nest: {
      strong: {
        color: "#fff",
        marginBottom: "10px"
      },
      p: {
        color: "#fff"
      }
    }
  },
  legals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    textAlign: "center",

    $nest: {
      "@media screen and (max-width: 800px)": {
        flexDirection: "column",
        fontSize: "13px",
        gap: "20px",
        fontWeight: 300
      }
    }
  },
  terms: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexWrap: "wrap",

    $nest: {
      a: {
        padding: "0",
        paddingInline: "7.5px",
        borderRight: "1px solid #ffffff55",
        marginBottom: "5px",

        $nest: {
          "&:last-child": {
            borderRight: "none",
            paddingRight: "0"
          },
          "&:first-child": {
            paddingLeft: "0"
          }
        }
      }
    }
  },
  arrow: {
    display: "none",

    $nest: {
      "@media screen and (max-width: 800px)": {
        gap: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingBlock: "20px",
        width: "100%",
        borderBottom: "1px solid #ffffff80",
        cursor: "pointer",
        marginBottom: "20px"
      },

      p: {
        color: "#fff"
      }
    }
  },
  companies: {
    display: "grid",
    gap: "10px",
    gridTemplateColumns: "1fr 1fr",

    $nest: {
      "@media screen and (max-width: 500px)": {
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        flexWrap: "wrap"
      }
    }
  },

  socials: {
    display: "flex",
    gap: "15px",
    marginTop: "60px",

    $nest: {
      "@media screen and (max-width: 800px)": {
        marginTop: "25px",
        marginBottom: "10px",
        gap: "10px"
      }
    }
  }
});

export default style;
