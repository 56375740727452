import { stylesheet } from "typestyle";
import { azure, black, clearBlue, lightGrey } from "../../utils/colors";

const loginStyles = stylesheet({
  container: {
    width: "100%",
    minHeight: "100vh"
  },
  imageContainer: {
    width: "100%"
  },
  emailContainer: {
    marginRight: "2em"
  },
  image: {
    display: "block",
    backgroundSize: "cover",
    height: "100vh",
    width: "50%",
    objectFit: "cover",
    top: 0,
    willChange: "opacity",
    position: "absolute"
  },
  link: {
    textDecoration: "underline",
    color: clearBlue,
    cursor: "pointer"
  },
  footer: {
    fontSize: "0.8em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.4em",
    color: clearBlue,
    padding: "30% 0 0 0",
    $nest: {
      "& > a": {
        color: "currentColor"
      }
    }
  },
  title: {
    fontSize: "2.5em",
    fontWeight: "bold",
    paddingBottom: "1.5em",
    marginRight: "2em"
  },
  subtitle: {
    fontSize: "2.2em",
    fontWeight: "bold",
    padding: "2em 0 2em 0",
    textAlign: "center",
    color: "#000000",
    marginRight: "1em"
  },
  text: {
    textAlign: "center",
    padding: "0 0 1.5em 0",
    color: "#000000"
  },
  inputsContainer: {
    padding: "15% 20% 0 20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#FFF",
    $nest: {
      "& > div": {
        maxWidth: "27em",
        width: "100%",
        margin: "0 auto"
      }
    }
  },
  error: {
    minHeight: "2em",
    color: "red",
    textAlign: "center",
    marginTop: "1em"
  },
  button: {
    background: lightGrey,
    color: "#fff",
    padding: "10px 21px 10px 22px",
    cursor: "pointer",
    marginTop: "2em",
    width: "100%",
    $nest: {
      "&:hover": {
        backgroundColor: azure
      }
    }
  },
  logo: {
    height: "29px",
    margin: "0 auto",
    display: "block"
  },
  footerText: {
    color: black
  }
});

export default loginStyles;
