import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { LanguageCode } from "../utils/types";

export interface LanguageStore {
  language: LanguageCode;
  setLanguage: (language: LanguageCode) => void;
}

export const useLanguageStore = create<LanguageStore>()(
  devtools(
    persist(
      (set) => ({
        language: "en",
        setLanguage: (language) => set({ language })
      }),
      { name: "language" }
    )
  )
);
