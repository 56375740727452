import { stylesheet } from "typestyle";

const style = stylesheet({
  container: {
    background: "#FFF",
    padding: "0 calc(10px + 40 * ((100vw - 320px) / 1400))",
    margin: "1em calc(80px + 40 * ((100vw - 320px) / 1400))",
    $nest: {
      "@media screen and (max-width: 800px)": {
        display: "none",
        margin: "calc(10px + 40 * ((100vw - 320px) / 1400))"
      }
    }
  },
  colorContainer: {
    borderRadius: "30px",
    padding: "0 calc(10px + 40 * ((100vw - 320px) / 1400))"
  },
  mobileContainer: {
    background: "#FFF",
    padding: "calc(10px + 100 * ((100vw - 320px) / 1400)) calc(20px + 200 * ((100vw - 320px) / 1400))",
    $nest: {
      "@media screen and (min-width: 800px)": {
        display: "none",
        padding: 0
      }
    }
  },
  videoContainer: {
    height: '623px',
    position: "relative",
    top: 0,
    left: 0,
    $nest: {
      "@media screen and (max-width: 800px)": {
        height: '323px',
      }
    }
  },
  imageWrapVideo: {
    height: "623px",
    position: "relative",
    top: 0,
    left: 0,
    $nest: {
      "@media screen and (max-width: 800px)": {
        height: "323px"
      }
    }
  },

  gradient: {
    width: "100%",
    position: "relative"
  },
  captures: {
    position: "absolute",
    zIndex: 2,
    top: "5em",
    right: 0,
    maxWidth: "40vw",
    width: "100%",
    paddingTop: "2em"
  },
  content: {
    fontSize: "calc(16px + 22 * ((100vw - 320px) / 1400))",
    borderRadius: "10px"
  },
  textContent: {
    padding: "calc(10px + 90 * ((100vw - 320px) / 1400))"
  },
  text: {
    color: "rgba(29, 38, 78, 1)",
    fontWeight: 600,
    fontSize: "40px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "30px"
      }
    }
  },

  textSmall: {
    color: "rgba(29, 38, 78, 1)",
    lineHeight: "30px",
    paddingTop: "clamp(1em, 10vw, 1em)",
    fontWeight: 400,
    fontSize: "18px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "16px"
      }
    }
  },
  subtitle: {
    color: "#1D264E",
    lineHeight: "1.5em",
    paddingBottom: "0em",
    fontWeight: 500,
    fontSize: "32px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "24px"
      }
    }
  },

  textSmall2: {
    color: "rgba(29, 38, 78, 1)",
    lineHeight: "1.5em",
    paddingTop: "clamp(1em, 5vw, 1em)",
    fontWeight: 300,
    fontSize: "18px",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "16px"
      }
    }
  },

  title: {
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 300,
    fontSize: "calc(48px * ((100vw - 320px) / 1400))",
    paddingBottom: "0.2em",
    $nest: {
      "@media screen and (max-width: 800px)": {
        fontSize: "calc(46px + 30 * ((100vw - 320px) / 1400))"
      }
    }
  },
  link: {
    color: "#ed1e79",
    fontSize: "calc(16px + 10 * ((100vw - 320px) / 1400))",
    padding: "1em 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  image: {
    maxWidth: "100%",
    width: "100%",
    position: "relative",
    top: 0,
    left: 0,

    $nest: {
      "@media screen and (max-width: 800px)": {}
    }
  },

  imageTickets: {
    position: "absolute",
    width: "80%",
    bottom: 0,
    left: "30%",
    zIndex: 1
  },
  imagePkPass: {
    position: "absolute",
    width: "60%",
    bottom: 0,
    left: 0
  },
  imageContainer: {
    position: "relative",
    top: 0,
    left: 0
  },
  icon: {
    width: "3em",
    height: "3em",
    filter: "drop-shadow(-3px 3px 20px rgba(103, 151, 255, 0.15))",
    $nest: {
      "@media screen and (max-width: 800px)": {
        display: "none"
      }
    }
  },
  logo: {
    width: "8em",
    fontSize: "calc(14px + 4 * ((100vw - 320px) / 1200))"
  },
  orange: {
    color: "#ff781f"
  },
  button: {
    color: "#fff"
  },
  buttonContainer: {
    margin: "clamp(1em, 20vw, 1em) 0"
  }
});

export default style;
